import {
  Container,
  Flex,
  useTheme,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
import { startCase, camelCase } from 'lodash';
import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {H3, TextG14} from '../../components/typography';
import useAuth from '../../hooks/useAuth';
import EventChatTab from './EventChatTab';
import GroupAndCommunity from './GroupAndCommunity';
import PlaceChatTab from './PlaceChatTab';

const CHATS_TABS = ['Group & Community', 'Events', 'Places'];

const Chats = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {user} = useAuth();
  const userCommunities = user?.communities;
  const userHasMembership = user?.hasActiveMembership;

  let [searchParams, setSearchParams] = useSearchParams();

  const getDefaultTab = () => {
    const tabFromParams = searchParams.get('tab');
    const tabIndex = CHATS_TABS.indexOf(startCase(camelCase(tabFromParams || CHATS_TABS[0])));
    return tabIndex >= 0 ? tabIndex : 0; // Default to the first tab if not found
  };

  const [tabIndex, setTabIndex] = useState(getDefaultTab());

  // Update the tab index when searchParams change
  useEffect(() => {
    const newTabIndex = getDefaultTab();
    setTabIndex(newTabIndex);
  }, [searchParams]);

  // Update the searchParams when tabIndex changes
  useEffect(() => {
    setSearchParams({['tab']: CHATS_TABS[tabIndex]}, {replace: true});
  }, [tabIndex]);

  useEffect(() => {
    window.history.scrollRestoration = 'auto';
  }, []);

  return (
    <Container pb={10} h="auto">
      <Tabs
        outline={'none !important'}
        index={tabIndex}
        onChange={(index) => setTabIndex(index)}
        isFitted
        isLazy
        lazyBehavior="keepMounted">
        <Flex
          flexDir="column"
          w="100vw"
          ml={-4}
          justifyContent="center"
          background={theme.colors.lightGrey}>
          <H3 mt="25px" mb="10px" mx={4} lineHeight="24px" color={theme.colors.black}>
            Chats
          </H3>
          <TabList w="100vw" justifyContent="center" px={2}>
            {CHATS_TABS.map((tab, index) => (
              <Tab
                px={0}
                _selected={{
                  color: theme.colors.blue,
                  borderBottomColor: theme.colors.blue,
                  borderBottomWidth: '3px',
                }}
                key={index}>
                <TextG14
                  fontWeight={tabIndex == index ? 700 : 500}
                  color={tabIndex == index ? theme.colors.blue : theme.colors.grey}>
                  {tab}
                </TextG14>
              </Tab>
            ))}
          </TabList>
        </Flex>

        <TabPanels zIndex="1000" background="white">
          <TabPanel p={0}>
            <GroupAndCommunity userCommunities={userCommunities} />
          </TabPanel>
          <TabPanel p={0}>
            <EventChatTab />
          </TabPanel>
          <TabPanel p={0}>
            <PlaceChatTab />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
};

export default Chats;
