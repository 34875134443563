import React from 'react';

const DotSeparator = ({
  width = '3px',
  height = '3px',
  color = 'white',
  marginLeft = '6px',
  marginRight = '6px',
  marginTop = '0px',
}) => {
  return (
    <div
      style={{
        margin: `${marginTop} ${marginRight} 0 ${marginLeft}`,
        width: `${width}`,
        height: `${height}`,
        borderRadius: '50%',
        background: `${color}`,
        display: 'inline-block',
      }}
    />
  );
};

export default DotSeparator;
