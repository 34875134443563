import {Center, Spinner, useTheme} from '@chakra-ui/react';
import dayjs from 'dayjs';
import {some} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import {useFetchQRDetails} from '../../hooks/useQRCodeDetails';
import useTrackEvent from '../../hooks/useTrackEvent';
import EditQRModal from './EditQRModal';

const EDITOR_ROLES = ['ADMIN', 'QR_EDITOR'];

const QRCodeHandler = () => {
  const theme = useTheme();
  const {slug} = useParams();
  const {data, isLoading, isError} = useFetchQRDetails(slug);
  const navigate = useNavigate();
  const {addUserEvent} = useTrackEvent();
  const {user} = useAuth();

  const [editModalOpen, setEditModalOpen] = useState(false);

  const redirectURL = data?.data?.qr_code.url;
  const qrCodeId = data?.data?.qr_code.id;

  const handleRedirect = () => {
    if (redirectURL) {
      addUserEvent({
        type: 'redirect',
        event_name: 'QR_code_redirect',
        event_time: dayjs().format(),
        data: {redirectUrl: redirectURL, slug: slug},
      });

      if (redirectURL.includes('my.walkabout.app')) {
        const url = new URL(redirectURL);

        // Add or append the `qr_code_id` parameter
        url.searchParams.set('qr_code_id', qrCodeId);

        // Remove the protocol and domain to make it a relative URL
        let relativeURL = url.href.replace(/^https?:\/\/[^/]+/, '');

        if (!relativeURL || relativeURL == '') {
          relativeURL = '/home';
        }

        navigate(relativeURL, {replace: true});
      } else {
        window.location.href = redirectURL;
      }

      return;
    }

    if ((!redirectURL && !isLoading) || isError) {
      addUserEvent({
        type: 'redirect',
        event_name: 'QR_code_error',
        event_time: dayjs().format(),
        data: {slug: slug},
      });

      navigate('/home', {replace: true});
    }
  };

  useEffect(() => {
    if (user) {
      const canEdit = user.roles?.some((role) => EDITOR_ROLES.includes(role.name));

      if (canEdit) {
        setEditModalOpen(true);
        return;
      }
    }

    if (!editModalOpen) {
      handleRedirect();
    }
  }, [data, user]);

  return (
    <Center h="100vh">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor={theme.colors.lightGrey}
        color={theme.colors.lightBlue}
        size="lg"
      />
      <EditQRModal
        isOpen={editModalOpen}
        acceptCallback={() => navigate(`/q/edit/${slug}`)}
        declineCallback={() => {
          handleRedirect();
          setEditModalOpen(false);
        }}
      />
    </Center>
  );
};

export default QRCodeHandler;
