import {Flex, HStack, Divider} from '@chakra-ui/react';
import React from 'react';
import {TextG14} from '../../components/typography';
import CommunityChatCard from './ChatCard/CommunityChatCard';
import LifestyleChatCard from './ChatCard/LifestyleChatCard';

const GroupAndCommunity = ({userCommunities}) => {
  return (
    <>
      <Flex flexDir="column">
        <TextG14 fontWeight="700" pt={4} pb={2}>
          Group Chat
        </TextG14>
        <HStack mb={2} gap={6} flexWrap="wrap" justifyContent="center">
          <LifestyleChatCard />;
        </HStack>
        <Divider />
      </Flex>
      {userCommunities && userCommunities.length > 0 && (
        <Flex flexDir="column">
          <TextG14 fontWeight="700" pt={4} pb={2}>
            Community Chat
          </TextG14>
          <HStack mb={2} gap={6} flexWrap="wrap" justifyContent="center">
            {userCommunities.map((community) => {
              if (community.chat_disabled) {
                return null;
              }
              return <CommunityChatCard key={community.id} community={community} />;
            })}
          </HStack>
          <Divider />
        </Flex>
      )}
    </>
  );
};

export default GroupAndCommunity;
