import {Flex, useTheme} from '@chakra-ui/react';
import React from 'react';
import Icon from '../../Icon';
import {TextG10} from '../../typography';

const NotWalkaboutAffiliatedPill = () => {
  const theme = useTheme();

  return (
    <Flex
      background={theme.colors.blueShade2}
      padding="2px 6px"
      borderRadius="4px"
      my="6px"
      align="center"
      gap="4px"
      maxWidth="240px"
      justifyContent="center"
      textAlign="center"
      w="fit-content"
      fontWeight="500"
      color={theme.colors.blue}>
      <Icon
        iconName="fi-rr-exclamation"
        style={{height: '12px', width: '12px', fontSize: '12px'}}
      />
      <TextG10>Not Organized by Walkabout</TextG10>
    </Flex>
  );
};

export default NotWalkaboutAffiliatedPill;
