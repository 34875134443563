import React, {useState} from 'react';
import {Divider, useTheme} from '@chakra-ui/react';
import {fetchArticles} from '../../endpoints/api';
import {TextA18, TextG12} from '../typography';
import useBookmark from '../../hooks/useBookmark';
import InfiniteScroll from '../InfiniteScroll';
import HomeFeed from '../HomeFeed';
import {BookmarkIcon} from '../../pages/Bookmarks';
import QueryInfiniteScroll from '../QueryInfiniteScroll';
import {getArticleTopItems} from '../../utils/articleTopItems';
import HomeContext from '../HomeContext';
import useAuth from '../../hooks/useAuth';
import PromptModal from '../Modals/PromptModal';
import dayjs from 'dayjs';
import {getCalenderDate} from '../../utils/date';
import {ArticleType, Event} from '../../types/acrticle';
import {EventRSVPStatus, useSetAttendance} from '../../hooks/useEvent';

export interface Article {
  id: number;
  start_date: string;
  created_at: string;
  image_url: string;
  title: string;
  description: string;
  location: string;
  user: {
    name: string;
  };
  type: string;
  is_bookmarked: boolean;
}

export const BookmarkElement = ({article}: {article: ArticleType}) => {
  const {isBookmarked, handleOnBookmarkClick} = useBookmark(
    article,
    typeof article.type == 'object' ? 'place' : article?.type?.toLowerCase()
  );
  const [isPromptOpen, setPromptOpen] = useState(false);
  const [promptTitle, setPromptTitle] = useState('');
  const dayWords = ['Now', 'Tomorrow', 'Today'];
  const {mutateAsync} = useSetAttendance();

  const handleBookmarkClick = (e) => {
    handleOnBookmarkClick(e);

    if (
      !isBookmarked &&
      'is_walkabout_official' in article &&
      !Boolean((article as Event)?.is_walkabout_official) &&
      (article as Event)?.rsvp_status?.status_value != EventRSVPStatus.INTERESTED
    ) {
      mutateAsync({
        eventId: article.id,
        prevStatus: (article as Event).rsvp_status.status_value || EventRSVPStatus.NO_STATUS,
        status: EventRSVPStatus.INTERESTED,
        showToast: false,
        showDispatchRSVPModalPopupActions: true,
      });
    }
  };

  const type =
    typeof article.type == 'object' || article.type == null || article.type == 'article'
      ? 'place'
      : article.type;

  const getPromptTitle = () => {
    const dateTimeNow = dayjs().format('YYYY-MM-DD HH:mm:ss').toString();

    if (article.type === 'event' && dateTimeNow < article.start_date_time) {
      const articleDate = getCalenderDate(article.start_date_time).replace(/(:00)/g, '');
      const shouldShowOn = !dayWords.some((day) => articleDate.startsWith(day));
      setPromptTitle(
        `This event is happening ${
          shouldShowOn ? 'on' : ''
        } ${articleDate}. Are you sure you want to remove from Bookmarks?`
      );
      return;
    }

    if (article.type === 'offer' && dateTimeNow < article.ends_at) {
      const articleDate = getCalenderDate(article.ends_at).replace(/(:00)/g, '');
      setPromptTitle(
        `This offer is still valid until ${articleDate}. Are you sure you want to remove from Bookmarks?`
      );
      return;
    }

    setPromptTitle(`Are you sure you want to remove this ${type} from Bookmarks?`);
  };

  return (
    <>
      <BookmarkIcon
        onClick={(e) => {
          if (isBookmarked) {
            e.preventDefault();
            e.stopPropagation();
            getPromptTitle();
            setPromptOpen(true);
          } else {
            e.preventDefault();
            e.stopPropagation();
            handleBookmarkClick(e);
          }
        }}
        isBookmarked={isBookmarked}
        pos={'absolute'}
        right={1}
        top={2}
        w={'26px'}
        h={'26px'}
        zIndex={10}
      />
      <PromptModal
        title={promptTitle}
        declineText={'Keep'}
        acceptText={'Remove'}
        declineCallback={() => setPromptOpen(false)}
        acceptCallback={(e) => {
          handleBookmarkClick(e);
          setPromptOpen(false);
          //window.location.reload();
        }}
        isOpen={isPromptOpen}
      />
    </>
  );
};

const Articles = ({
  children,
  title,
  subtitle,
  type,
  endpoint,
  endpointParams,
  noItems,
  renderArticleType,
  hook = null,
}) => {
  const theme = useTheme();

  return (
    <>
      {title && (
        <TextA18 mb={2} color={theme.colors.darkGrey}>
          {title}
        </TextA18>
      )}
      {subtitle && <TextG12 color={theme.colors.darkGrey}>{subtitle}</TextG12>}
      {(title || subtitle) && <Divider my={3} color={theme.colors.lighterGrey} />}
      {type === 'home' ? (
        <HomeFeed />
      ) : hook ? (
        <QueryInfiniteScroll
          showItems={(item) => {
            return React.cloneElement(children, {
              [type]: item,
              ...getArticleTopItems({...item, type: item.type || type}),
            });
          }}
          noItems={noItems}
          hook={hook}
          endpointParams={{...(type !== 'home' && type !== 'article' && {type}), ...endpointParams}}
        />
      ) : (
        <InfiniteScroll
          showItems={(item) => {
            return React.cloneElement(children, {
              [type]: item,
              ...getArticleTopItems({...item, type: item.type || type}),
            });
          }}
          noItems={noItems}
          endpoint={endpoint}
          endpointParams={{...(type !== 'home' && type !== 'article' && {type}), ...endpointParams}}
        />
      )}
    </>
  );
};

Articles.defaultProps = {
  type: 'article',
  title: '',
  subtitle: '',
  endpoint: fetchArticles,
  endpointParams: {},
  noItems: null,
  renderArticleType: true,
  isLoading: true,
};

export default Articles;
