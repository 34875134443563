import {Flex, FlexProps, Switch, SwitchProps, TextProps, useTheme} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {EventRSVPStatus, useSetAttendance} from '../../hooks/useEvent';
import {Event} from '../../types/acrticle';
import {TextG14} from '../typography';

interface InterestToggleProps {
  event: Event;
  height?: FlexProps['height'];
  width?: FlexProps['width'];
  fontSize?: TextProps['fontSize'];
  switchSize?: SwitchProps['size'];
  switchLocation?: 'left' | 'right';
  hasBorder?: boolean;
}

const InterestedToggle = ({
  event,
  height = '40px',
  width = '100%',
  fontSize = '14px',
  switchSize = 'md',
  switchLocation = 'right',
  hasBorder = true,
}: InterestToggleProps) => {
  const theme = useTheme();
  const rsvpStatus = event.rsvp_status;

  const [toggleIntereted, setToggleInterested] = useState(
    rsvpStatus.status_value == EventRSVPStatus.INTERESTED
  );
  const {mutateAsync} = useSetAttendance();

  useEffect(() => {
    if (rsvpStatus) {
      setToggleInterested(rsvpStatus.status_value == EventRSVPStatus.INTERESTED);
    }
  }, [rsvpStatus]);

  // Toggle function to update the state
  const toggleInterestedChanged = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const interested = e.target.checked;
    const status = interested ? EventRSVPStatus.INTERESTED : EventRSVPStatus.NOT_INTERESTED;
    setToggleInterested(interested);

    mutateAsync({
      eventId: event.id,
      prevStatus: rsvpStatus.status_value || EventRSVPStatus.NO_STATUS,
      status,
      showToast: false,
      showDispatchRSVPModalPopupActions: interested ? true : false,
    });
  };

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setToggleInterested((prevStatus) => !prevStatus);
    const interested = !toggleIntereted;

    const status = interested ? EventRSVPStatus.INTERESTED : EventRSVPStatus.NOT_INTERESTED;

    mutateAsync({
      eventId: event.id,
      prevStatus: rsvpStatus.status_value || EventRSVPStatus.NO_STATUS,
      status,
      showToast: false,
      showDispatchRSVPModalPopupActions: interested ? true : false,
    });
  };

  return (
    <Flex
      onClick={handleClick}
      border={hasBorder ? `1px solid ${toggleIntereted ? '#329241' : theme.colors.grey}` : 'none'}
      w={width}
      height={height}
      borderRadius="22.5px"
      gap="9px"
      justifyContent="center"
      alignItems="center">
      {switchLocation == 'left' && (
        <Switch
          size={switchSize}
          colorScheme="green"
          isChecked={toggleIntereted}
          defaultChecked={toggleIntereted}
          onChange={toggleInterestedChanged}
        />
      )}
      <TextG14 fontSize={fontSize} color="#242629" fontWeight="500">
        I'm Interested
      </TextG14>
      {switchLocation == 'right' && (
        <Switch
          size={switchSize}
          colorScheme="green"
          isChecked={toggleIntereted}
          defaultChecked={toggleIntereted}
          onChange={toggleInterestedChanged}
        />
      )}
    </Flex>
  );
};

export default InterestedToggle;
