import React, {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
  useAddCommentImage,
  useCreatePlaceComment,
  useDeleteCommentImage,
  useDeletePlaceComment,
  useFetchPlaceComment,
  useHelpfulPlaceComment,
  useReportPlaceComment,
  useUpdatePlaceComment,
  useFetchPlaceChatUsers,
} from '../../../hooks/usePlaceComments';
import ChatComment, {ChatMedia} from '../ChatComment';
import {AbsoluteCenter, Center, Flex, Spinner, useTheme} from '@chakra-ui/react';
import {TextG10, TextG14} from '../../typography';
import ChatHeader from '../ChatHeader/PlaceChatHeader';
import Icon from '../../Icon';
import useAuth from '../../../hooks/useAuth';
import {getImage} from '../../../pages/Profile/Edit/CropImage';
import {useFetchPlace} from '../../../hooks/useFetchPlace';

const CommentThread = () => {
  const {user} = useAuth();
  const [isErrored, setIsErrored] = useState(false);
  const {placeId, commentId} = useParams();
  const fetchComment = useFetchPlaceComment({
    placeId: Number(placeId),
    commentId,
    enabled: !isErrored,
  });
  const fetchPlace = useFetchPlace({id: Number(placeId)});
  const place = fetchPlace.data?.data;
  const comment = fetchComment.data?.data;
  const navigate = useNavigate();
  const theme = useTheme();
  const [headerHeight, setHeaderHeight] = useState(0);

  const addPlaceComment = useCreatePlaceComment();
  const updatePlaceComment = useUpdatePlaceComment();
  const deletePlaceComment = useDeletePlaceComment();
  const reportPlaceComment = useReportPlaceComment();
  const helpfulPlaceComment = useHelpfulPlaceComment();
  const addCommentImage = useAddCommentImage();
  const deleteCommentImage = useDeleteCommentImage();

  const addComment = async (content: string, parent_id?: string, imageURLs?: Array<any>) => {
    if (content.length > 0) {
      const comment = await addPlaceComment.mutateAsync({
        placeId: place.id,
        content,
        parent_id,
        user,
        hasImage: imageURLs && imageURLs.length > 0,
      });

      if (imageURLs && imageURLs.length > 0 && comment) {
        imageURLs.map(async (media) => {
          const croppedImage = await getImage(media, null);

          if (placeId && croppedImage) {
            await addCommentImage.mutateAsync({
              formData: croppedImage,
              target_id: placeId,
              comment_id: comment.data.id,
            });
          }
        });
      }
    }
  };

  const updateComment = async (
    commentId: string,
    content: string,
    parent_id?: string,
    imageURLs?: Array<any>,
    mediaToDelete?: ChatMedia[]
  ) => {
    if (content.length > 0) {
      await updatePlaceComment.mutateAsync({
        placeId: place.id,
        commentId,
        content,
      });

      if (imageURLs && imageURLs.length > 0) {
        imageURLs.map(async (media) => {
          const croppedImage = await getImage(media, null);
          if (placeId && croppedImage && commentId) {
            await addCommentImage.mutateAsync({
              formData: croppedImage,
              target_id: placeId,
              comment_id: commentId,
            });
          }
        });
      }

      if (mediaToDelete && mediaToDelete.length > 0) {
        mediaToDelete.map(async (media) => {
          if (media.id) {
            await deleteCommentImage.mutateAsync({mediaId: media.id});
          }
        });
      }
    }
  };

  const deleteComment = async (commentId: string) => {
    await deletePlaceComment.mutateAsync({
      placeId: place.id,
      commentId,
    });
  };

  const reportComment = async (commentId: string) => {
    await reportPlaceComment.mutateAsync({
      placeId: place.id,
      commentId,
    });
  };

  const helpfulComment = async (commentId: string) => {
    await helpfulPlaceComment.mutateAsync({
      placeId: place.id,
      commentId,
    });
  };

  useEffect(() => {
    if (fetchComment.isError) setIsErrored(true);
  }, [fetchComment]);

  if (!place) return <></>;

  return (
    <Flex flexDir="column" pt={`${headerHeight}px`} px={4} pb="77px">
      <ChatHeader
        addComment={addComment}
        updateComment={updateComment}
        deleteComment={deleteComment}
        reportComment={reportComment}
        helpfulComment={helpfulComment}
        place={place}
        setHeaderHeight={setHeaderHeight}
        showTabs={false}
      />
      {fetchComment.isLoading || fetchPlace.isLoading ? (
        <Center h={`calc(100vh - ${headerHeight}px)`}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor={theme.colors.lightGrey}
            color={theme.colors.lightBlue}
            size="lg"
          />
        </Center>
      ) : !comment || isErrored ? (
        <AbsoluteCenter
          w="95%"
          textAlign="center"
          color={theme.colors.grey}
          pt={`${headerHeight / 2}px`}>
          <Flex flexDir="column" gap={8}>
            <Icon iconName="fi-rr-comment" style={{fontSize: '48px'}} />
            <TextG14 fontWeight="500">Sorry, this Comment is no longer available.</TextG14>
          </Flex>
        </AbsoluteCenter>
      ) : (
        <>
          <Flex w="100%" justify="space-between" mt={2} color={theme.colors.blue}>
            {comment.parent_id && (
              <TextG10 onClick={() => navigate(`/place/${placeId}/chat/${comment.parent_id}`)}>
                See parent thread
              </TextG10>
            )}
            <TextG10 onClick={() => navigate(`/place/${placeId}/chat`)}>
              See full discussion
            </TextG10>
          </Flex>
          <ChatComment
            fetchUsersHook={useFetchPlaceChatUsers}
            addComment={addComment}
            updateComment={updateComment}
            deleteComment={deleteComment}
            reportComment={reportComment}
            helpfulComment={helpfulComment}
            isReply={comment.parent_id}
            comment={comment}
            articleId={place.id}
            name={place.name}
            isRSVPd={true}
            showUserRSVP={false}
            repliesShowingDefault
          />
        </>
      )}
    </Flex>
  );
};

export default CommentThread;
