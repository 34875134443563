import React, {useState, useEffect} from 'react';
import {createStandaloneToast, ChakraProvider, useMediaQuery} from '@chakra-ui/react';
import {BrowserRouter as Router} from 'react-router-dom';
import Routes from './routes';
import theme from './theme';
import Page from './components/Page';
import {AuthProvider} from './hooks/useAuth';
import {useSelector} from 'react-redux';
import ModalNotification from './state/ModalNotification/ModalNotification';
import {CheckInProvider} from './hooks/useCheckIn';
import ScrollTop from './components/ScrollTop';
import {QueryClientProvider, QueryClient} from '@tanstack/react-query';
import {CookiesProvider} from 'react-cookie';
import BottomSheetNotification from './state/BottomSheetNotification/BottomSheetNotification';
import Offline from './pages/Offline';
import {useLocalStorage} from '@rehooks/local-storage';
import dayjs from 'dayjs';
import AlertPopup from './state/AlertPopup/AlertPopup';
import InstallAppPrompt from './state/InstallAppPrompt';
import {
  isIphoneAndSafari,
  isStandalone,
  isMobile,
  isIphoneAndChrome,
  isIphone,
} from './utils/deviceCheck';
import {ActiveRedemptionsContextProvider} from './hooks/useActiveRedemptions';
import DownloadApp from './pages/DownloadApp';
import {GlobalSearchProvider} from './hooks/useGlobalSearch';
// import { SuperFiltersProvider } from "./hooks/useSuperFilters";
import useAuth, {USER_TOKEN} from './hooks/useAuth';
import EnchantLauncher from './components/EnchantLauncher';
import {OfferModalCotainer} from './hooks/useOfferModal';
import RSVPModalPopup from './state/RSVPModalPopup/RSVPModalPopup';
import EventNotificationsModal from './state/EventNotificationsModal';
import {BROWSER_ENABLED_ROUTES} from './constants/routes';
import NotificationsReminderPopup from './state/NotificationReminderPopup';

const updateLocale = require('dayjs/plugin/updateLocale');
const relativeTime = require('dayjs/plugin/relativeTime');
const calendar = require('dayjs/plugin/calendar');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
const isBetween = require('dayjs/plugin/isBetween');

dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.extend(calendar);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

dayjs.tz.setDefault('America/Los_Angeles');

const {ToastContainer} = createStandaloneToast();

export const queryClient = new QueryClient({
  defaultOptions: {queries: {staleTime: 20000}},
});

export const INSTALL_PROMPT = 'install_prompt';
export const IOS_PROMPT = 'ios_prompt';

function App() {
  //const [isMobile] = useMediaQuery('(max-width: 810px)', {fallback: true});
  const notificationReminder = useSelector((state) => state.notificationReminder);
  const [modalNotification] = useSelector((state) => state.modalNotifications);
  const [bottomSheetNotification] = useSelector((state) => state.bottomSheetNotifications);
  const [alertPopupNotification] = useSelector((state) => state.alertPopup);
  const [rsvpModalPopup] = useSelector((state) => state.rsvpModalPopup);
  const [eventNotificationsModal] = useSelector((state) => state.eventNotificationsModal);
  const [onlineStatus, setOnlineStatus] = useState(navigator.onLine);
  const [installPromptVisits, setInstallPromptVisits, removeInstallPromptVisits] =
    useLocalStorage(INSTALL_PROMPT);
  const [iOSInstallPrompt] = useLocalStorage(IOS_PROMPT, isIphoneAndSafari);
  const [userToken] = useLocalStorage(USER_TOKEN);
  const [showInstallPrompt, setShowInstallPrompt] = useState(false);
  const {user} = useAuth();

  useEffect(() => {
    if (!isStandalone()) {
      if (installPromptVisits === null) {
        const promptData = {
          clicks_count: 0,
          prompt_mod: 5,
        };
        setInstallPromptVisits(JSON.stringify(promptData));
      }
    } else removeInstallPromptVisits(INSTALL_PROMPT);
    // window.addEventListener("load", e => {
    //     if (navigator.getInstalledRelatedApps) {
    //     navigator.getInstalledRelatedApps()
    //     .then(relatedApps => {
    //       console.log(relatedApps);
    //     });
    //   }
    // });
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();

      // Stash the event so it can be triggered later.
      window.deferredPrompt = e;
    });
    window.addEventListener('appinstalled', () => {
      removeInstallPromptVisits(INSTALL_PROMPT);

      // Clear the deferredPrompt so it can be garbage collected
      window.deferredPrompt = null;
    });
  }, []);

  useEffect(() => {
    const setOffline = () => setOnlineStatus(false);
    const setOnline = () => setOnlineStatus(true);

    window.addEventListener('offline', setOffline);
    window.addEventListener('online', setOnline);

    return () => {
      window.removeEventListener('offline', setOffline);
      window.removeEventListener('online', setOnline);
    };
  }, []);

  function nativeCallback(event) {
    const message = event.data;

    if (message.type === 'locationUpdates') {
      // const {location} = message;
      // console.log(location);
      // if (window["ReactNativeWebView"]) window['ReactNativeWebView'].postMessage('Acknowledged');
      // Handle location permission change from the React Native app
    }
  }

  useEffect(() => {
    document.addEventListener('message', nativeCallback); // android
    window.addEventListener('message', nativeCallback); // ios
    return () => {
      document.removeEventListener('message', nativeCallback);
      window.removeEventListener('message', nativeCallback);
    };
  }, []);

  useEffect(() => {
    if (isMobile && !window['ReactNativeWebView'] && user) {
      // The page is not being rendered in an iframe
      if (window.self == window.top) {
        setShowInstallPrompt(true);
      }
    }
  }, [user]);

  const isBrowserRoute = BROWSER_ENABLED_ROUTES.some((regex) =>
    regex.test(window.location.pathname)
  );

  return (
    <>
      <EnchantLauncher />
      <ChakraProvider theme={theme}>
        {!onlineStatus ? (
          <Offline text="No Internet connection. Make sure that Wi-Fi or mobile data is turned on, then try again." />
        ) : (
          <QueryClientProvider client={queryClient}>
            {!isMobile && !isBrowserRoute ? (
              <DownloadApp />
            ) : (
              <CookiesProvider>
                <Router>
                  <ToastContainer />
                  <ScrollTop />
                  <AuthProvider>
                    <ActiveRedemptionsContextProvider>
                      <OfferModalCotainer>
                        <CheckInProvider>
                          <GlobalSearchProvider>
                            {/* <SuperFiltersProvider> */}
                            <Page>
                              <Routes />
                              {modalNotification && <ModalNotification {...modalNotification} />}
                              {bottomSheetNotification && (
                                <BottomSheetNotification notification={bottomSheetNotification} />
                              )}
                              {alertPopupNotification && (
                                <AlertPopup notification={alertPopupNotification} />
                              )}
                              {rsvpModalPopup && <RSVPModalPopup {...rsvpModalPopup} />}
                              {eventNotificationsModal && (
                                <EventNotificationsModal {...eventNotificationsModal} />
                              )}
                              {showInstallPrompt && (
                                <InstallAppPrompt
                                  showInstallPrompt={showInstallPrompt}
                                  setShowInstallPrompt={setShowInstallPrompt}
                                />
                              )}
                              {notificationReminder.show && <NotificationsReminderPopup />}
                            </Page>
                            {/* </SuperFiltersProvider> */}
                          </GlobalSearchProvider>
                        </CheckInProvider>
                      </OfferModalCotainer>
                    </ActiveRedemptionsContextProvider>
                  </AuthProvider>
                </Router>
              </CookiesProvider>
            )}
          </QueryClientProvider>
        )}
      </ChakraProvider>
    </>
  );
}

export default App;
