import React, {useEffect, useMemo, useState} from 'react';
import {Flex, HStack, useTheme} from '@chakra-ui/react';
import {Event} from '../../../types/acrticle';
import {H3, TextG10, TextG14} from '../../../components/typography';
import {getEventDate} from '../../../utils/date';
import {getDisplayPrice} from '../../../utils/price';
import Icon from '../../../components/Icon';
import EventCountdown from '../../Event/EventCountdown';
import {EventRSVPStatus} from '../../../hooks/useEvent';
import RSVPSelect from '../../Event/RSVP/RSVPSelect';
import {BlueButton} from '../../../components/button';
import {useNavigate, useSearchParams} from 'react-router-dom';
import SignupRequiredModal from '../../../components/SignupRequiredModal';
import IconsHeader from '../PreviewIcons';
import useAuth from '../../../hooks/useAuth';
import NotWalkaboutAffiliatedPill from '../../../components/Articles/EventCard/NotWalkaboutAffiliatedPill';

const Header: React.FC<{event: Event}> = ({event}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const [signUpRequiredOpen, setSignUpRequiredOpen] = useState(false);
  const {user} = useAuth();

  const isWalkaboutEvent = Boolean(event.is_walkabout_official);

  const eventDate = useMemo(
    () =>
      !!event.is_recurring
        ? event.recurring_schedule
        : getEventDate(event.start_date_time, event.end_date_time),
    [event]
  );

  const [inviteCode, setInviteCode] = useState('');
  const [ni, setNi] = useState('');
  const [qrCodeId, setQRCodeId] = useState('');

  useEffect(() => {
    if (!!searchParams.get('invite-code') && searchParams?.get('invite-code') !== '') {
      setInviteCode(searchParams?.get('invite-code') || '');
    }

    if (!!searchParams.get('ni') && searchParams?.get('ni') !== '') {
      setNi(searchParams?.get('ni') || '');
    }

    if (!!searchParams.get('qr_code_id') && searchParams?.get('qr_code_id') !== '') {
      setQRCodeId(searchParams?.get('qr_code_id') || '');
    }
  }, [searchParams]);

  const iconStyle: React.CSSProperties = useMemo(
    () => ({
      color: theme.colors.grey,
      fontSize: '12px',
      marginRight: '5px',
    }),
    [theme]
  );

  const onClickRSVPModal = (status: EventRSVPStatus) => {
    setIsOpen(false);
    setSignUpRequiredOpen(true);
  };

  const onSignupClicked = () => {
    if (window.self !== window.top) {
      // The page is being rendered in an iframe
      window.top ? (window.top.location.href = 'https://my.walkabout.app/soft-check') : null;
      return;
    } else {
      // The page is not in an iframe
      if (user) {
        navigate('/download');
      } else if (inviteCode) {
        navigate('/register', {state: {code: inviteCode, ni, qrCodeId: qrCodeId}});
      } else if (qrCodeId) {
        navigate('/register', {state: {qrCodeId: qrCodeId}});
      } else {
        navigate('/soft-check');
      }
    }
  };

  const closeHandler = () => setIsOpen(false);
  const onClickRSVPButton = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };

  return (
    <>
      <Flex
        id="rsvp-tooltip-highlight"
        position="relative"
        top="0"
        width="90%"
        marginLeft="5%"
        transform="auto"
        translateY="-90px"
        marginBottom="-5rem"
        backgroundColor="white"
        boxShadow={`0 0 12px ${theme.colors.grey}`}
        borderRadius=".5rem"
        p="0.75rem"
        pb="0"
        flexDirection={'column'}>
        <Flex width="100%" flexDirection="column">
          <Flex direction="column" gap="0.25rem">
            <HStack>
              {!!event.is_recurring && <Icon iconName="fi-rr-time-past" style={iconStyle} />}
              <TextG10
                ml="0 !important"
                color={theme.colors.grey}
                isTruncated="&hellip;"
                noOfLines={1}>
                {eventDate}
              </TextG10>
            </HStack>
            <H3>{event.title}</H3>
            {!isWalkaboutEvent && <NotWalkaboutAffiliatedPill />}
          </Flex>
        </Flex>

        <Flex my={3} justifyContent="space-between" h="25px">
          <Flex flexShrink={0}>
            {(event.price || event?.price_override) && (
              <TextG14 sx={{marginTop: 'auto'}} fontWeight={500}>
                {getDisplayPrice(event.price, event?.max_price, event?.price_override)}
              </TextG14>
            )}
          </Flex>
        </Flex>
        <Flex direction={'column'} gap={'.5rem'}>
          {isWalkaboutEvent && (
            <BlueButton onClick={onClickRSVPButton} mb="-10px">
              <TextG14 fontWeight="500">RSVP & Join Walkabout</TextG14>
            </BlueButton>
          )}
          <IconsHeader
            event={event}
            setSignUpRequiredOpen={setSignUpRequiredOpen}
            inviteCode={inviteCode}
          />
          <EventCountdown event={event} />
        </Flex>
        {isWalkaboutEvent && (
          <RSVPSelect
            onOptionClick={onClickRSVPModal}
            onModalClose={closeHandler}
            openModal={isOpen}
            event={event}
          />
        )}
        <SignupRequiredModal
          isOpen={signUpRequiredOpen}
          setIsOpen={setSignUpRequiredOpen}
          onButtonClicked={onSignupClicked}
        />
      </Flex>
    </>
  );
};

export default Header;
