import {useInfiniteQuery, useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {
  addChatMedia,
  createPlaceComment,
  deleteChatMedia,
  deletePlaceComment,
  fetchChatMedia,
  fetchPlaceChatUsers,
  fetchPlaceComment,
  fetchPlaceComments,
  fetchPlaceReplyMentionComments,
  fetchFeaturedPlaceComments,
  helpfulPlaceComment,
  reportPlaceComment,
  updatePlaceComment,
  fetchPlaceChats,
} from '../endpoints/api';
import {User} from './useAuth';
import {tail} from 'lodash';
import {ToastMessage} from '../components/toast';
import {AxiosError} from 'axios';

const INTERVAL_MS = 5000; // 5 seconds

export const useInfiniteFeaturedPlaceComments = (placeId: number, enabled: boolean = true) => {
  return useInfiniteQuery(
    ['infinite_featured_place_comments', placeId],
    ({pageParam = 1}) => fetchFeaturedPlaceComments({placeId, page: pageParam}),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.current_page !== lastPage.data.last_page) {
          return lastPage.data.current_page + 1;
        }
      },
      refetchInterval: INTERVAL_MS,
      enabled: enabled && typeof placeId === 'number',
    }
  );
};

export const useInfinitePlaceComments = (placeId: number, enabled: boolean = true) => {
  return useInfiniteQuery(
    ['infinite_place_comments', placeId],
    ({pageParam = 1}) => fetchPlaceComments({placeId, page: pageParam}),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.current_page !== lastPage.data.last_page) {
          return lastPage.data.current_page + 1;
        }
      },
      refetchInterval: INTERVAL_MS,
      enabled: enabled && typeof placeId === 'number',
    }
  );
};

export const useCreatePlaceComment = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      placeId,
      content,
      parent_id,
      is_featured,
      user,
      hasImage = false,
    }: {
      placeId: number;
      content: string;
      parent_id?: string;
      is_featured?: boolean;
      user?: User;
      hasImage?: boolean;
    }) => createPlaceComment({placeId, content, parent_id, is_featured}),
    {
      onMutate: async (newComment) => {
        if (newComment.parent_id) return;
        await queryClient.cancelQueries({
          queryKey: ['infinite_place_comments', newComment.placeId],
        });

        const previousComments: any = queryClient.getQueryData([
          'infinite_place_comments',
          newComment.placeId,
        ]);

        const newData = {
          ...previousComments,
          pages: [
            {
              ...previousComments.pages[0],
              data: {
                ...previousComments.pages[0].data,
                data: [
                  {hasImage: true, id: '1', ...newComment},
                  ...previousComments.pages[0].data.data,
                ],
              },
            },
            ...tail(previousComments.pages),
          ],
        };

        if (previousComments && previousComments.pages[0]) {
          queryClient.setQueryData(['infinite_place_comments', newComment.placeId], newData);
        }

        return {previousComments, newComment};
      },
      onSettled: (newComment, error, variables, context) => {
        if (error && error instanceof AxiosError) {
          ToastMessage({
            status: 'error',
            text: error.message,
          });
        }
      },
    }
  );
};

export const useUpdatePlaceComment = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({placeId, commentId, content}: {placeId: number; commentId: string; content: string}) =>
      updatePlaceComment({placeId, commentId, content}),
    {
      onSettled: (newComment, error, variables, context) => {
        if (error && error instanceof AxiosError) {
          ToastMessage({
            status: 'error',
            text: error.message,
          });
        }
        queryClient.invalidateQueries(['infinite_place_comments', variables.placeId]);
        queryClient.invalidateQueries(['place_comments', variables.placeId]);
      },
    }
  );
};

export const useDeletePlaceComment = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({placeId, commentId}: {placeId: number; commentId: string}) =>
      deletePlaceComment({placeId, commentId}),
    {
      onSettled: (newComment, error, variables, context) => {
        if (error && error instanceof AxiosError) {
          ToastMessage({
            status: 'error',
            text: error.message,
          });
        }
        queryClient.invalidateQueries(['infinite_place_comments', variables.placeId]);
        queryClient.invalidateQueries(['place_comments', variables.placeId]);
      },
    }
  );
};

export const useReportPlaceComment = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({placeId, commentId}: {placeId: number; commentId: string}) =>
      reportPlaceComment({placeId, commentId}),
    {
      onSettled: (newComment, error, variables, context) => {
        queryClient.invalidateQueries(['infinite_place_comments', variables.placeId]);
        queryClient.invalidateQueries(['place_comments', variables.placeId]);
      },
    }
  );
};

export const useHelpfulPlaceComment = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({placeId, commentId}: {placeId: number; commentId: string}) =>
      helpfulPlaceComment({placeId, commentId}),
    {
      onSettled: (newComment, error, variables, context) => {
        queryClient.invalidateQueries(['infinite_place_comments', variables.placeId]);
        queryClient.invalidateQueries(['place_comments', variables.placeId]);
      },
    }
  );
};

export function useFetchPlaceChatUsers(placeId: number) {
  return useQuery(['place_chat_num', placeId], () => fetchPlaceChatUsers({placeId}), {
    enabled: typeof placeId === 'number',
  });
}

export function useFetchRepliesAndMentions(placeId: number, enabled: boolean = true) {
  return useQuery(['place_replies', placeId], () => fetchPlaceReplyMentionComments({placeId}), {
    enabled: enabled && typeof placeId === 'number',
    // Refetch the data every INTERVAL_MS
    refetchInterval: INTERVAL_MS,
  });
}

export function useFetchPlaceComment({placeId, commentId, enabled}) {
  return useQuery(
    ['place_comment', placeId, commentId],
    () => fetchPlaceComment({placeId, commentId}),
    {
      enabled: enabled && typeof placeId === 'number',
      // Refetch the data every INTERVAL_MS
      refetchInterval: INTERVAL_MS,
      retry: 1,
    }
  );
}

export const useAddCommentImage = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      formData,
      target_id,
      comment_id,
    }: {
      formData: FormData;
      target_id: string;
      comment_id: string;
    }) =>
      addChatMedia({
        formData,
        target_type: 'place',
        target_id,
        comment_type: 'place_comment',
        comment_id,
      }),
    {
      onSettled: (newComment, error, variables, context) => {
        if (error && error instanceof AxiosError) {
          ToastMessage({
            status: 'error',
            text: error.message,
          });
        }
        queryClient.invalidateQueries(['infinite_place_comments', variables.target_id]);
        queryClient.invalidateQueries(['place_comments', variables.target_id]);
      },
    }
  );
};

export const useDeleteCommentImage = () => {
  const queryClient = useQueryClient();
  return useMutation(({mediaId}: {mediaId: string}) => deleteChatMedia({mediaId}), {
    onSettled: (newComment, error, variables, context) => {
      if (error && error instanceof AxiosError) {
        ToastMessage({
          status: 'error',
          text: error.message,
        });
      }
      queryClient.invalidateQueries(['infinite_place_comments']);
      queryClient.invalidateQueries(['place_comments']);
    },
  });
};

export const useFetchCommentMedia = ({mediaId}) => {
  return useQuery(['place_comment_media', mediaId], () => fetchChatMedia({mediaId}), {
    enabled: typeof mediaId === 'number',
  });
};

export const useFetchPlaceChats = () => {
  return useInfiniteQuery(
    ['infinite_place_chats'],
    ({pageParam = '1'}) => fetchPlaceChats({pageParam}),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.current_page !== lastPage.data.last_page) {
          return lastPage.data.current_page + 1;
        }
      },
    }
  );
};
