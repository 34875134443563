import React from 'react';
import PromptModal from '../../components/Modals/PromptModal';

const EditQRModal = ({isOpen, acceptCallback, declineCallback}) => {
  return (
    <PromptModal
      title={'Would you like to edit this QR code?'}
      isOpen={isOpen}
      acceptText="Yes"
      declineText="No"
      acceptCallback={acceptCallback}
      declineCallback={declineCallback}
    />
  );
};

export default EditQRModal;
