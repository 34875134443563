import {Center, Spinner, Flex, useTheme} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {useParams, useNavigate, useSearchParams} from 'react-router-dom';
import MediaGallery from '../../../components/MediaGallery';
import SignupRequiredModal from '../../../components/SignupRequiredModal';
import {fetchPublicOffer} from '../../../endpoints/api';
import useAuth from '../../../hooks/useAuth';
import useOfferModal from '../../../hooks/useOfferModal';
import {useFetchPublicPartner} from '../../../hooks/usePartner';
import {isMobile} from '../../../utils/deviceCheck';
import Header from '../Header';
import PartnerTabs from '../PartnerTabs';

const PartnerPreview = ({onClose}: {onClose?: () => void}) => {
  const {partnerId} = useParams();
  const theme = useTheme();
  const [signUpRequiredOpen, setSignUpRequiredOpen] = useState(false);
  const navigate = useNavigate();
  const {user} = useAuth();

  const [searchParams] = useSearchParams();

  const [inviteCode, setInviteCode] = useState('');
  const [qrCodeId, setQRCodeId] = useState('');

  useEffect(() => {
    if (!!searchParams.get('invite-code') && searchParams?.get('invite-code') !== '') {
      setInviteCode(searchParams?.get('invite-code') || '');
    }

    if (!!searchParams.get('qr_code_id') && searchParams?.get('qr_code_id') !== '') {
      setQRCodeId(searchParams?.get('qr_code_id') || '');
    }
  }, [searchParams]);

  const onSignupClicked = () => {
    if (window.self !== window.top) {
      // The page is being rendered in an iframe
      window.top ? (window.top.location.href = 'https://my.walkabout.app/soft-check') : null;
      return;
    } else {
      // The page is not in an iframe
      if (user) {
        navigate('/download');
      } else if (inviteCode) {
        navigate('/register', {state: {code: inviteCode, qrCodeId: qrCodeId}});
      } else if (qrCodeId) {
        navigate('/register', {state: {qrCodeId: qrCodeId}});
      } else {
        navigate('/soft-check');
      }
    }
  };

  const fetchPartner = useFetchPublicPartner(partnerId);
  const partner = fetchPartner.data?.data;

  const {showOfferModal} = useOfferModal();

  useEffect(() => {
    if (searchParams.get('offer')) {
      fetchPublicOffer(searchParams.get('offer')).then(({data}) => {
        if (user && partner && isMobile) {
          navigate(`/partner/${partner.id}?offer=${data.id}`, {replace: true});
        } else {
          showOfferModal({offer: data, isPreview: true});
        }
      });
    } else if (user && partner) {
      isMobile ? navigate(`/partner/${partner.id}`, {replace: true}) : null;
    }
  }, [user, partner, searchParams.get('offer')]);

  if (fetchPartner.isLoading || partner === undefined || (user && isMobile)) {
    return (
      <Center style={{minHeight: '100vh'}}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );
  }

  return (
    <>
      <Flex position="relative" height="275px">
        <MediaGallery media={partner.media} />
      </Flex>
      <Header partner={partner} />
      <PartnerTabs setSignUpRequiredOpen={setSignUpRequiredOpen} isPreview partner={partner} />
      <SignupRequiredModal
        isOpen={signUpRequiredOpen}
        setIsOpen={setSignUpRequiredOpen}
        onButtonClicked={onSignupClicked}
      />
    </>
  );
};

export default PartnerPreview;
