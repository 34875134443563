import React, {useEffect, useMemo, useState} from 'react';
import {Box, Flex, HStack, useTheme} from '@chakra-ui/react';
import {Link as DomLink, useNavigate} from 'react-router-dom';
import {ROUTES} from '../../constants/routes';
import {Event, Offer} from '../../types/acrticle';
import {H3, TextG10, TextG12} from '../../components/typography';
import {BlueButton, WhiteButton} from '../../components/button';
import {calculateAndGetDistance, getDistanceString} from '../../utils/distance';
import {registerTicketUrlClick} from '../../endpoints/api';
import useGetUserLocation from '../../hooks/useUserLocation';
import {getEventDate} from '../../utils/date';
import {getDisplayPrice} from '../../utils/price';
import Icon from '../../components/Icon';
import {storeScrollPositionInSession} from '../../utils/scroll';
import IconsHeader from './About/IconsHeader';
import ConvinceMeToGoButton from './ConvinceMeToGoButton';
import EventCountdown from './EventCountdown';
import {EventRSVPStatus, useFetchEventRSVPStats, useSetAttendance} from '../../hooks/useEvent';
import {generatePath, useSearchParams} from 'react-router-dom';
import DotSeparator from '../../components/DotSeparator';
import useCountdown from '../../hooks/useCountdown';
import useCheckIn from '../../hooks/useCheckIn';
import PromptModal from '../../components/Modals/PromptModal';
import useActiveRedemptions from '../../hooks/useActiveRedemptions';
import CardBadge from '../../components/CardBadge';
import RSVPController from './RSVP/RSVPController';
import useAuth from '../../hooks/useAuth';
import UseablePassPill from '../../components/Articles/EventCard/UseablePassPill';
import ExternalLink from '../../components/ExternalLink';
import {useFetchUserTickets} from '../../hooks/useUserTickets';
import {filter} from 'lodash';
import NotWalkaboutAffiliatedPill from '../../components/Articles/EventCard/NotWalkaboutAffiliatedPill';
import InterestedToggle from '../../components/InterestedToggle';
import PointBoostBadge from '../../components/Articles/PlaceCard/PointBoostBadge';

const Header: React.FC<{
  event: Event;
  eventOffers: Array<Offer>;
  disableLocation: () => void;
}> = ({event, eventOffers, disableLocation}) => {
  const theme = useTheme();
  const userLocation = useGetUserLocation();
  const {startPassed, endPassed, canCheckIn} = useCountdown(event);
  const [isClosedModel, setIsClosedModel] = useState(() => event.rsvp_status.status_value === null);
  const {mutateAsync} = useSetAttendance();
  const [searchParams] = useSearchParams();
  const {data} = useFetchEventRSVPStats(Number(event.id || searchParams.get('id')));
  const rsvp_stats = data?.data.rsvp_stats;
  const {eventCheckIn} = useCheckIn();
  const [promptOpen, setPromptOpen] = useState(false);
  const {activeRedemptions} = useActiveRedemptions();
  const {user} = useAuth();
  const eventDiscount = user?.activeMembershipPlan?.event_discount;
  const navigate = useNavigate();

  const fetchTickets = useFetchUserTickets(!!user);
  const tickets = fetchTickets.data?.data;
  const eventTickets = filter(tickets, (ticket) => ticket.event_id == event.id);
  const userHasTickets = eventTickets && eventTickets.length > 0;

  const action = useMemo(() => searchParams.get('action'), [searchParams]);
  const isLoading = userLocation.isLoading;

  const isWalkaboutEvent = Boolean(event.is_walkabout_official);
  const interestedAndGoing = rsvp_stats?.INTERESTED + rsvp_stats?.GOING;

  useEffect(() => {
    if (!userLocation.isLoading && action && action == 'check-in') {
      const qr_code_id_string = searchParams.get('qr_code_id');
      let qr_code_id;
      if (qr_code_id_string) {
        qr_code_id = parseInt(qr_code_id_string);
      }
      handleCheckIn({sdv: !!searchParams.get('sdv'), qr_code_id});
    }
  }, [action, isLoading]);

  const eventDate = useMemo(
    () =>
      !!event.is_recurring
        ? event.recurring_schedule
        : getEventDate(event.start_date_time, event.end_date_time),
    [event]
  );

  const iconStyle: React.CSSProperties = useMemo(
    () => ({
      color: theme.colors.grey,
      fontSize: '12px',
      marginRight: '5px',
    }),
    [theme]
  );

  const statusValue = event.rsvp_status.status_value || EventRSVPStatus.NO_STATUS;

  const handleCheckIn = ({sdv, qr_code_id}) => {
    if (event.has_checked_in) {
      return;
    }

    if (!canCheckIn) {
      setPromptOpen(true);
      return;
    }

    if (userLocation.error && !sdv) {
      disableLocation();
      return;
    }

    if ((!userLocation.data?.latitude || !userLocation.data?.longitude) && !sdv) {
      return;
    }

    eventCheckIn({
      event: event,
      eventOffers: eventOffers,
      activeRedemptions: activeRedemptions,
      latitude: userLocation?.data?.latitude,
      longitude: userLocation?.data?.longitude,
      isPublic: true,
      sdv: sdv,
      qr_code_id,
    });
  };

  return (
    <>
      <PromptModal
        title={
          'You can Check-In when the Event starts. Would you like to set a reminder for this Event?'
        }
        isOpen={promptOpen}
        declineText={'No'}
        acceptText={'Yes'}
        declineCallback={() => setPromptOpen(false)}
        acceptCallback={(e) => {
          if (
            !(
              event.rsvp_status.status_value == EventRSVPStatus.GOING ||
              event.rsvp_status.status_value == EventRSVPStatus.INTERESTED
            )
          ) {
            mutateAsync({
              eventId: event.id,
              prevStatus: statusValue,
              status: EventRSVPStatus.INTERESTED,
            });
          }
          setPromptOpen(false);
        }}
      />
      <Flex
        id="rsvp-tooltip-highlight"
        position="relative"
        top="0"
        width="90%"
        marginLeft="5%"
        transform="auto"
        translateY="-90px"
        marginBottom="-5rem"
        backgroundColor="white"
        boxShadow={`0 0 12px ${theme.colors.grey}`}
        borderRadius=".5rem"
        p="0.75rem"
        pb="0"
        flexDirection={'column'}>
        {event.is_walkabout_official ? (
          <CardBadge position="absolute" top="-6px" label="WALKABOUT OFFICIAL" center="true" />
        ) : null}
        <Flex width="100%" flexDirection="column" mt={event.is_walkabout_official ? '8px' : 0}>
          <Flex direction="column" gap="5px">
            <HStack>
              {!!event.is_recurring && <Icon iconName="fi-rr-time-past" style={iconStyle} />}
              <TextG10
                ml="0 !important"
                color={theme.colors.grey}
                isTruncated="&hellip;"
                noOfLines={1}>
                {eventDate}
              </TextG10>
            </HStack>
            <H3>{event.title}</H3>
            {!isWalkaboutEvent && <NotWalkaboutAffiliatedPill />}
            <PointBoostBadge article={event} />
            <UseablePassPill event={event} />
            {event.place && (
              <HStack mt="4px">
                <DomLink
                  style={{
                    color: '#276ACD',
                    fontWeight: '500',
                    fontFamily: 'Gordita',
                    fontSize: '12px',
                  }}
                  to={generatePath(ROUTES.PLACE, {
                    placeId: event.place.id.toString(),
                  })}
                  onClick={() => storeScrollPositionInSession()}>
                  <TextG12>{event.place.name}</TextG12>
                </DomLink>
                <DotSeparator color="black" />
                {userLocation.data?.latitude &&
                  userLocation.data.longitude &&
                  event.place?.latitude &&
                  event.place?.longitude && (
                    <TextG12 m="0 !important" noOfLines={1} minWidth="90px">
                      {getDistanceString(
                        calculateAndGetDistance(
                          {latitude: event.place?.latitude, longitude: event.place?.longitude},
                          userLocation.data
                        )
                      )}{' '}
                      away
                    </TextG12>
                  )}
              </HStack>
            )}
          </Flex>
        </Flex>
        <Flex
          my={3}
          justifyContent={isWalkaboutEvent && interestedAndGoing > 0 ? 'space-between' : 'flex-end'}
          h="25px"
          alignItems="center">
          {isWalkaboutEvent && interestedAndGoing > 0 && (
            <Flex gap={2} w="100%" align="center" wrap="wrap">
              {/* <AvatarGroup users={usersGoing} /> */}
              <TextG12 fontWeight={'500'}>
                {rsvp_stats?.INTERESTED + rsvp_stats?.GOING} Interested & Going
              </TextG12>
            </Flex>
          )}

          <Flex gap="5px" whiteSpace="nowrap">
            <TextG12
              fontSize={event.has_tickets && eventDiscount && eventDiscount > 0 ? '10px' : '12px'}
              color={theme.colors.lightBlack}
              as={
                event.price > 0 &&
                event.has_tickets &&
                eventDiscount &&
                eventDiscount > 0 &&
                !event.price_override
                  ? 's'
                  : ''
              }>
              {getDisplayPrice(event.price, event?.max_price, event?.price_override)}
            </TextG12>
            {event.price > 0 &&
              event.has_tickets &&
              eventDiscount &&
              eventDiscount > 0 &&
              !event.price_override && (
                <TextG12 color={theme.colors.lightBlack}>
                  {getDisplayPrice(event.price - (event.price * eventDiscount) / 100)}
                </TextG12>
              )}
          </Flex>
          {/* <Flex flexShrink={0}>
            {(event.price || event?.price_override) && (
              <TextG14 sx={{marginTop: 'auto'}} fontWeight={500}>
                {getDisplayPrice(event.price, event?.max_price, event?.price_override)}
              </TextG14>
            )}
          </Flex> */}
        </Flex>
        <Flex direction={'column'} gap={'.5rem'}>
          <Flex flexDir="column">
            {event.has_tickets ? (
              <BlueButton
                isDisabled={endPassed && !userHasTickets}
                onClick={() =>
                  userHasTickets
                    ? navigate(`/tickets/${event.id}`)
                    : navigate(`/event/${event.id}/tickets`, {replace: true})
                }
                w="100%"
                border={endPassed ? '' : `1px solid ${theme.colors.blue}`}
                flexGrow={1}>
                <TextG12 fontWeight="500">{userHasTickets ? 'See Tickets' : 'Get Tickets'}</TextG12>
              </BlueButton>
            ) : event.tickets_url ? (
              <ExternalLink href={event.tickets_url} w="100%" isExternal>
                <BlueButton
                  w="100%"
                  border={`1px solid ${theme.colors.blue}`}
                  flexGrow={1}
                  onClick={() => {
                    registerTicketUrlClick(event.id).catch((e) => console.log(e));
                  }}>
                  <TextG12 fontWeight="500">Get Tickets</TextG12>
                  {/* {event.price && isFree(event.price) ? 'Get Tickets' : 'Buy Tickets'} */}
                </BlueButton>
              </ExternalLink>
            ) : null}
            {!isWalkaboutEvent && (
              <Box mt="0.5rem">
                <InterestedToggle event={event} />
              </Box>
            )}
          </Flex>
          {isWalkaboutEvent && (
            <Flex gap={'.5rem'} justifyContent="center" alignItems="center">
              {event.allow_check_in ? (
                event.rsvp_status.status_value === null || isClosedModel ? (
                  <ConvinceMeToGoButton
                    event={event}
                    typographySize="md"
                    modelClosedEvent={() => setIsClosedModel(false)}
                    textColor={event.rsvp_status.status_value === null ? 'blue' : 'black'}
                    background="white"
                    buttonProps={{
                      flexGrow: 1,
                      height: '2.5rem',
                      border: '1px solid blue',
                      width: '50%',
                      maxWidth: '50%',
                      minWidth: '50%',
                    }}
                  />
                ) : (
                  <WhiteButton
                    id="checkin-tooltip"
                    onClick={handleCheckIn}
                    w="50%"
                    minW="50%"
                    maxW="50%"
                    flexGrow={1}
                    borderRadius={'2.5rem'}
                    _hover={{background: ''}}
                    color={!canCheckIn ? theme.colors.lightestGrey : theme.colors.white}
                    background={
                      !canCheckIn
                        ? theme.colors.lighterGrey
                        : event.has_checked_in
                          ? 'green'
                          : theme.colors.blue
                    }
                    isDisabled={endPassed}>
                    {event.has_checked_in ? (
                      <Flex gap="6px" style={{color: 'white'}}>
                        <Icon iconName="fi-sr-check" style={{marginTop: '2px'}} />
                        <TextG12 fontWeight="500">Checked-In</TextG12>
                      </Flex>
                    ) : (
                      <TextG12 fontWeight="500">Check-In</TextG12>
                    )}
                  </WhiteButton>
                )
              ) : null}
              <RSVPController
                buttonProps={{
                  border: event.rsvp_status.status_value ? '1px solid black' : '1px solid black',
                  width: '50%',
                  maxWidth: '50%',
                  minWidth: '50%',
                }}
                event={event}
              />
            </Flex>
          )}
        </Flex>
        <IconsHeader event={event} />
        <EventCountdown event={event} />
      </Flex>
      {/* <RSVPSelect
        onOptionClick={onClickRSVPModal}
        onModalClose={closeHandler}
        openModal={isOpen}
        event={event}
      /> */}
    </>
  );
};

export default Header;
