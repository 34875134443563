import {
  background,
  Box,
  BoxProps,
  Divider,
  Flex,
  HStack,
  Image,
  Skeleton,
  useTheme,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, {useEffect, useRef, useState} from 'react';
import {CommentOption, OPTIONS, USER_OPTIONS} from '../../../constants/commentOptions';
import useAuth, {User} from '../../../hooks/useAuth';
import {EventRSVPStatus} from '../../../hooks/useEvent';
import Icon from '../../Icon';
import PromptModal from '../../Modals/PromptModal';
import {TextG10, TextG12} from '../../typography';
import UserAvatar from '../../UserAvatar';
import CommentInput from './CommentInput';
import {OPTIONS as EventOptions} from '../../../constants/eventOptions';
import {useNavigate} from 'react-router-dom';
import BottomSheetSelect from '../../BottomSheetSelect';
import {ToastMessage} from '../../toast';
import {find, orderBy} from 'lodash';
import RoseLogo from '../../../assets/logos/logo_rose2.png'; //'../../assets/logos/logo_rose2.png';
import RoleBadge from './RoleBadge';
import ImageGallery from 'react-image-gallery';
// import stylesheet if you're not already using CSS @import
import 'react-image-gallery/styles/css/image-gallery.css';
import {fetchChatMedia} from '../../../endpoints/api';
import Modal from '../../Modals/Modal';
import {TransformComponent, TransformWrapper} from 'react-zoom-pan-pinch';
interface ChatCommentProps extends BoxProps {
  fetchUsersHook: any;
  comment: ChatComment;
  articleId: number | undefined;
  name?: string;
  isRSVPd?: boolean;
  isReply?: boolean;
  repliesShowingDefault?: boolean;
  hideOptions?: boolean;
  eventChatClosed?: boolean;
  setInputOpen?: any;
  numOfLines?: number;
  setInputHidden?: any;
  addComment: (content: string, parent_id?: string, imageURLs?: Array<any>) => void;
  updateComment: (
    commentId: string,
    content: string,
    parent_id?: string,
    imageURLs?: Array<any>,
    mediaToDelete?: ChatMedia[]
  ) => void;
  deleteComment: (commentId: string) => void;
  reportComment: (commentId: string) => void;
  helpfulComment: (commentId: string) => void;
  showUserRSVP?: boolean;
}

export interface ChatComment {
  id: string;
  event_id: number;
  user_id: number;
  parent_id?: string;
  content: string;
  rsvp_status: EventRSVPStatus;
  is_featured: boolean;
  is_abusive: boolean;
  is_helpful: boolean;
  mentions?: Array<any>;
  status: number;
  created_at: string;
  updated_at: string;
  all_nested_replies?: Array<ChatComment>;
  user_image?: string;
  user: User;
  moreNestedComments?: boolean;
  isReply?: boolean;
  thread_media?: Array<ChatMedia>;
  hasImage?: boolean;
}

export interface ChatMedia {
  id: string;
  file_url: string;
  thumbnail_url: string;
}

function limitDepthOfReplies(comment: ChatComment, depthLimit: number): ChatComment {
  if (!comment.all_nested_replies || depthLimit === 0) {
    // If there are no nested replies or depth limit is reached, return the comment without nested replies.
    if (comment.all_nested_replies && comment.all_nested_replies.length > 0) {
      return {...comment, all_nested_replies: undefined, moreNestedComments: true};
    } else {
      return {...comment, moreNestedComments: false};
    }
  }

  // Recursively process and limit the depth of nested replies.
  const limitedReplies: ChatComment[] = [];
  let hasMoreNestedComments = false;

  for (const reply of comment.all_nested_replies) {
    const limitedReply = limitDepthOfReplies(reply, depthLimit - 1);
    if (limitedReply.moreNestedComments) {
      hasMoreNestedComments = true; // Set the flag to true if any nested comment was pruned
    }
    limitedReplies.push(limitedReply);
  }

  return {
    ...comment,
    all_nested_replies: limitedReplies,
    moreNestedComments: hasMoreNestedComments,
  };
}

const REPLY_DEPTH_LIMIT = 2;
export const MENTION_REGEXP = /@(\d+)\[(.*?)\]/g;

export const getUserHandle = (match, comment) => {
  let str;
  let id;
  let defaultHandle;

  while ((str = MENTION_REGEXP.exec(match)) !== null) {
    id = str[1];
    defaultHandle = str[2];
  }

  const mention = find(comment.mentions, (mention) => {
    return mention.id == id;
  });

  return {id: id, handle: (mention && mention.handle) || defaultHandle || ''};
};

function ColorizeText({text, regex, color}) {
  const parts = text.split(regex);

  return (
    <span>
      {parts.map((part, index) =>
        regex.test(part) ? (
          <span key={index} style={{color}}>
            <TextG12>{part}</TextG12>
          </span>
        ) : (
          <TextG12>{part}</TextG12>
        )
      )}
    </span>
  );
}

const ChatComment = ({
  comment,
  articleId,
  name,
  isReply,
  repliesShowingDefault = false,
  hideOptions = false,
  eventChatClosed = false,
  isRSVPd: isRSVPd,
  setInputOpen,
  setInputHidden,
  numOfLines,
  addComment,
  updateComment,
  deleteComment,
  reportComment,
  helpfulComment,
  fetchUsersHook,
  showUserRSVP,
  ...rest
}: ChatCommentProps) => {
  const {user} = useAuth();
  const [replyInputOpen, setReplyInputOpen] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [promptOpen, setPromptOpen] = useState(false);
  const [galleryOpen, setGalleryOpen] = useState(false);
  // Initialize the hasImage state with the current value of comment.hasImage
  const [hasImage, setHasImage] = useState(!!comment.hasImage);

  useEffect(() => {
    // Ensure that once hasImage becomes true, it stays true
    if (comment.hasImage && !hasImage) {
      setHasImage(true);
    }
  }, [comment.hasImage, hasImage]); // Track both comment.hasImage and hasImage to prevent flipping back

  const theme = useTheme();
  const navigate = useNavigate();
  const [repliesShowing, setRepliesShowing] = useState(repliesShowingDefault);
  // const isRVSPd =
  //   event &&
  //   event.rsvp_status.status_value &&
  //   event.rsvp_status.status_value != EventRSVPStatus.NOT_INTERESTED;
  const isDeleted = comment.content == 'Message deleted by author';

  useEffect(() => {
    setInputHidden && setInputHidden(replyInputOpen);
  }, [replyInputOpen]);

  dayjs.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: function (number, withoutSuffix) {
        return withoutSuffix ? 'now' : 'a few seconds';
      },
      m: '1 min',
      mm: '%d mins',
      h: '1 hr',
      hh: '%d hrs',
      d: '1 day',
      dd: '%d days',
      M: '1 mth',
      MM: '%d mth',
      y: '1 year',
      yy: '%d years',
    },
  });

  const handleAddComment = async (content: string, parent_id?: string, imageURLs?: Array<any>) => {
    addComment(content, parent_id, imageURLs);
    setReplyInputOpen(false);
  };

  const handleEditComment = async (
    content: string,
    parent_id?: string,
    imageURLs?: Array<any>,
    mediaToDelete?: ChatMedia[]
  ) => {
    updateComment(comment.id, content, parent_id, imageURLs, mediaToDelete);
    setEditOpen(false);
  };

  const handleDeleteComment = async () => {
    deleteComment(comment.id);
    setPromptOpen(false);
  };

  const handleReportComment = async () => {
    reportComment(comment.id);
    setPromptOpen(false);
  };

  const handleHelpfulComment = async () => {
    helpfulComment(comment.id);
    setPromptOpen(false);
  };

  const handleOptionClicked = (option: CommentOption) => {
    switch (option) {
      case CommentOption.REPORT:
        handleReportComment();
        break;
      case CommentOption.HELPFUL:
        handleHelpfulComment();
        break;
      case CommentOption.EDIT:
        setEditOpen(true);
        break;
      case CommentOption.DELETE:
        setOptionsOpen(false);
        setPromptOpen(true);
        break;
    }
    setOptionsOpen(false);
  };

  const getIconStyle = (option) => {
    {
      return {
        fontSize: '10px',
        height: '10px',
        width: '10px',
        color: theme.colors[option.color] || option.color,
        fill: theme.colors[option.color] || option.color,
      };
    }
  };

  const images = comment.thread_media?.map((media) => {
    return {id: media.id, original: media.file_url, thumbnail: media.thumbnail_url};
  });

  return (
    <>
      {images && images.length > 0 && (
        <Modal
          closeButtonColor="#9b9b9b"
          isCentered
          modalPadding={'0px !important'}
          headerPadding={'0px !important'}
          parentCallback={() => setGalleryOpen(false)}
          show={galleryOpen}
          closeButton>
          <ImageGallery
            // to render a pinch and zoom image instead, but would prevent swiping to prev/next images
            // renderItem={(item) => {
            //   return (
            //     <Flex background={theme.colors.darkGrey}>
            //       <TransformWrapper>
            //         <TransformComponent
            //           contentStyle={{width: '95vw', height: '85vh'}}
            //           wrapperStyle={{background: theme.colors.darkGrey}}>
            //           <Image
            //             fallbackStrategy="onError"
            //             src={item.original}
            //             alt="cover image"
            //             width="100%"
            //             height="100%"
            //             objectFit="contain"
            //             maxHeight="95vh"
            //             background={theme.colors.darkGrey}
            //           />
            //         </TransformComponent>
            //       </TransformWrapper>
            //     </Flex>
            //   );
            // }}
            lazyLoad
            showBullets
            showNav={false}
            //showThumbnails={false}
            showPlayButton={false}
            showFullscreenButton={false}
            items={images}
          />
        </Modal>
      )}
      <BottomSheetSelect
        options={user?.id == comment.user_id ? USER_OPTIONS : OPTIONS}
        openModal={optionsOpen}
        onModalClose={() => setOptionsOpen(false)}
        onClick={handleOptionClicked}
      />
      <PromptModal
        title={`Are you sure you want to permanently delete your message from '${
          name ? name : ''
        }'?`}
        declineText={'Keep'}
        acceptText={'Delete'}
        declineCallback={() => setPromptOpen(false)}
        acceptCallback={(e) => handleDeleteComment()}
        isOpen={promptOpen}
      />
      <Box my={2} pos="relative" w="100%" {...rest}>
        {!isReply &&
          repliesShowing &&
          comment.all_nested_replies &&
          comment.all_nested_replies.length > 0 && (
            <Divider left="17px" orientation="vertical" pos="absolute" height="100%" bottom={0} />
          )}
        <Flex flexDir="column">
          <Flex align="center" justify="space-between">
            <Flex align="center" gap={2}>
              <UserAvatar
                user={comment.user}
                size={isReply ? 'xs' : 'sm'}
                avatar_image={comment.user_image}
              />
              <Flex align="center" gap={1}>
                <TextG12
                  fontWeight={comment.user?.is_walkabout_employee ? '500' : '400'}
                  color={comment.user?.is_walkabout_employee ? theme.colors.rose : 'initial'}
                  noOfLines={1}>
                  {comment.user?.handle || 'Null User Handle'}
                </TextG12>
                <RoleBadge eventId={comment.event_id} commentUser={comment.user} />
                {showUserRSVP &&
                  comment.user.atm_rsvp_status &&
                  EventOptions[comment.user.atm_rsvp_status] &&
                  EventOptions[comment.user.atm_rsvp_status].renderSelectedIcon({
                    style: getIconStyle(EventOptions[comment.user.atm_rsvp_status]),
                  })}
                {/* <Image src={RoseLogo} w="10px" h="10px"/> */}
              </Flex>
              <TextG10 noOfLines={1} color={theme.colors.lightestGrey}>
                {dayjs.utc(comment.created_at).fromNow()}
              </TextG10>
            </Flex>
            {isDeleted || hideOptions || comment.is_featured ? null : (
              <Flex align="center" gap={0.5} mb="4px" onClick={() => setOptionsOpen(true)}>
                <Icon
                  iconName="fi-rr-menu-dots"
                  style={{
                    fontSize: '16px',
                    color: theme.colors.lightestGrey,
                    width: '16px',
                    height: '16px',
                  }}
                />
              </Flex>
            )}
          </Flex>
          {editOpen ? (
            <Box mt={2} ml={isReply ? '38px' : '44px'}>
              <CommentInput
                fetchUsersHook={fetchUsersHook}
                articleId={articleId}
                onCloseClick={() => setEditOpen(false)}
                onSaveClick={handleEditComment}
                media={comment.thread_media}
                defaultContent={comment.content.replace(MENTION_REGEXP, (match) =>
                  match.replace(
                    MENTION_REGEXP,
                    `@${getUserHandle(match, comment).id}[${getUserHandle(match, comment).handle}]`
                  )
                )}
              />
            </Box>
          ) : (
            <Flex ml={isReply ? '38px' : '44px'} flexDir="column" gap={2} whiteSpace="pre-line">
              {comment.is_featured ? (
                <TextG12
                  noOfLines={numOfLines}
                  maxHeight={numOfLines ? '15px' : null}
                  whiteSpace="normal"
                  dangerouslySetInnerHTML={{__html: comment.content}}>
                  {}
                </TextG12>
              ) : (
                <TextG12
                  dangerouslySetInnerHTML={{
                    __html: comment.content.replace(MENTION_REGEXP, (match) =>
                      match.replace(MENTION_REGEXP, `@${getUserHandle(match, comment).handle}`)
                    ),
                  }}>
                  {}
                </TextG12>
              )}

              {comment.thread_media && comment.thread_media.length > 0 ? (
                <HStack>
                  {comment.thread_media.map((media) => (
                    <Image
                      onClick={() => {
                        setGalleryOpen(true);
                      }}
                      key={media.id}
                      src={media.thumbnail_url}
                      height="60px"
                      objectFit="contain"
                      boxShadow="md"
                      borderRadius="4px"
                    />
                  ))}
                </HStack>
              ) : null}

              {hasImage && (comment.thread_media?.length || 0) <= 0 && (
                <Skeleton h="60px" w="60px" />
              )}

              {!comment.is_featured && (
                <Flex justify={isReply ? 'space-between' : 'flex-end'}>
                  {isReply && !replyInputOpen && !comment.is_featured && (
                    <Flex
                      onClick={() => {
                        if (comment.event_id && eventChatClosed) {
                          ToastMessage({
                            status: 'error',
                            text: 'This event has ended and the chat is closed.',
                          });
                        } else if (comment.event_id && !isRSVPd) {
                          ToastMessage({
                            status: 'error',
                            text: 'You must RSVP to this event to participate in chat.',
                          });
                        } else {
                          setInputOpen && setInputOpen(false);
                          setReplyInputOpen((prev) => {
                            if (!prev) setRepliesShowing(!prev);
                            return !prev;
                          });
                        }
                      }}
                      gap={1.5}>
                      <TextG10 color={theme.colors.blue}>Reply</TextG10>
                      {/* {comment.all_nested_replies && comment.all_nested_replies?.length > 0 && (
                      <TextG10 color={theme.colors.lightestGrey}>
                        {comment.all_nested_replies.length}{' '}
                        {comment.all_nested_replies.length == 1 ? 'reply' : 'replies'}
                      </TextG10>
                    )} */}
                    </Flex>
                  )}
                  {isReply && replyInputOpen && (
                    <CommentInput
                      fetchUsersHook={fetchUsersHook}
                      parentComment={comment}
                      articleId={articleId}
                      mt="10px"
                      onCloseClick={() => setReplyInputOpen(false)}
                      parentCommentId={comment.parent_id}
                      onSaveClick={handleAddComment}
                      isReply
                    />
                  )}
                  {!replyInputOpen && comment.created_at !== comment.updated_at && (
                    <TextG10 color={theme.colors.lightestGrey}>{`${
                      isDeleted ? 'Deleted' : 'Edited'
                    } ${dayjs.utc(comment.updated_at).fromNow()}`}</TextG10>
                  )}
                </Flex>
              )}
            </Flex>
          )}
          {!comment.is_featured &&
            comment.all_nested_replies &&
            !repliesShowing &&
            comment.all_nested_replies.length >= 1 && (
              <Box
                key={
                  orderBy(comment.all_nested_replies, 'created_at', 'asc')[
                    comment.all_nested_replies.length - 1
                  ].id
                }
                ml="38px"
                mt={2}
                pos="relative">
                <Divider
                  left="13px"
                  orientation="vertical"
                  pos="absolute"
                  h={'calc(100% - 20px)'}
                  bottom={'0px'}
                />
                <ChatComment
                  fetchUsersHook={fetchUsersHook}
                  articleId={articleId}
                  name={name}
                  isRSVPd={isRSVPd}
                  addComment={addComment}
                  updateComment={updateComment}
                  deleteComment={deleteComment}
                  reportComment={reportComment}
                  helpfulComment={helpfulComment}
                  setInputOpen={setInputOpen}
                  setInputHidden={setInputHidden}
                  comment={
                    orderBy(comment.all_nested_replies, 'created_at', 'asc')[
                      comment.all_nested_replies.length - 1
                    ]
                  }
                  isReply
                  repliesShowingDefault={repliesShowingDefault}
                  eventChatClosed={eventChatClosed}
                />
              </Box>
            )}
          {!comment.is_featured &&
            repliesShowing &&
            comment.all_nested_replies &&
            comment.all_nested_replies.length >= 1 &&
            orderBy(
              limitDepthOfReplies(comment, REPLY_DEPTH_LIMIT).all_nested_replies,
              'created_at',
              'asc'
            ).map((reply) => {
              return (
                <Box key={reply.id} ml="38px" mt={2} pos="relative">
                  <Divider
                    left="13px"
                    orientation="vertical"
                    pos="absolute"
                    h={'calc(100% - 20px)'}
                    bottom={'0px'}
                  />
                  <ChatComment
                    fetchUsersHook={fetchUsersHook}
                    articleId={articleId}
                    name={name}
                    isRSVPd={isRSVPd}
                    addComment={addComment}
                    updateComment={updateComment}
                    deleteComment={deleteComment}
                    reportComment={reportComment}
                    helpfulComment={helpfulComment}
                    setInputOpen={setInputOpen}
                    setInputHidden={setInputHidden}
                    comment={reply}
                    isReply
                    repliesShowingDefault={repliesShowingDefault}
                    eventChatClosed={eventChatClosed}
                  />
                  {/* {reply.moreNestedComments && (
                    <TextG12
                      mt="12px"
                      pl="60px"
                      onClick={() => {
                        event && navigate(`/event/${event.id}/chat/${reply.id}`);
                      }}
                      color={theme.colors.blue}>
                      See more replies
                    </TextG12>
                  )} */}
                </Box>
              );
            })}
          {comment.all_nested_replies && comment.all_nested_replies.length > 1 && (
            <Box
              position="relative"
              pl="38px"
              pr={4}
              py={4}
              w="60%"
              mt={2}
              onClick={() => setRepliesShowing((prev) => !prev)}>
              <Divider w="50%" mt={'-2px'} />
              <Box
                pos="absolute"
                bg="white"
                pl="1"
                pr="0"
                top="50%"
                left="50%"
                transform={'translate(0%, -50%)'}>
                <TextG10 fontWeight="500" color={theme.colors.lightestGrey}>
                  {repliesShowing
                    ? `Hide Replies`
                    : `View ${comment.all_nested_replies.length - 1} ${
                        comment.all_nested_replies.length - 1 == 1 ? 'more reply' : 'more replies'
                      }`}
                </TextG10>
              </Box>
            </Box>
          )}
        </Flex>
        {!isReply && !replyInputOpen && !comment.is_featured && (
          <Flex
            mt={0}
            pt={repliesShowing ? '15px' : '0px'}
            position="relative"
            background="white"
            ml="5px"
            onClick={() => {
              if (eventChatClosed) {
                ToastMessage({
                  status: 'error',
                  text: 'This event has ended and the chat is closed.',
                });
              } else if (!!!isRSVPd) {
                ToastMessage({
                  status: 'error',
                  text: 'You must RSVP to this event to participate in chat.',
                });
              } else {
                setInputOpen && setInputOpen(false);
                setReplyInputOpen((prev) => {
                  if (!prev) setRepliesShowing(!prev);
                  return !prev;
                });
              }
            }}
            gap={1.5}>
            <TextG10 color={theme.colors.blue}>Reply</TextG10>
            {/* {comment.all_nested_replies && comment.all_nested_replies?.length > 0 && (
                      <TextG10 color={theme.colors.lightestGrey}>
                        {comment.all_nested_replies.length}{' '}
                        {comment.all_nested_replies.length == 1 ? 'reply' : 'replies'}
                      </TextG10>
                    )} */}
          </Flex>
        )}
        {!isReply && replyInputOpen && (
          <CommentInput
            parentComment={comment.parent_id ? comment : undefined}
            fetchUsersHook={fetchUsersHook}
            articleId={articleId}
            mt="10px"
            onCloseClick={() => setReplyInputOpen(false)}
            parentCommentId={comment.id}
            onSaveClick={handleAddComment}
            isReply
          />
        )}
      </Box>
      {!isReply && <Divider mt={2} w="100%" />}
    </>
  );
};

export default ChatComment;
