import {
  Flex,
  Center,
  useTheme,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  AbsoluteCenter,
  TabPanels,
} from '@chakra-ui/react';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {BlueButtonFixedWidth} from '../../components/button';
import Icon from '../../components/Icon';
import QueryInfiniteScroll from '../../components/QueryInfiniteScroll';
import {TextG16, TextG18, TextG14, H3, TextG12} from '../../components/typography';
import {useEventRSVPs} from '../../hooks/useEvents';
import EventChatCard from './ChatCard/EventChatCard';

const CHATS_TABS = ['Active', 'Closed'];

const EventChatTab = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Tabs
      variant="enclosed"
      outline={'none !important'}
      index={tabIndex}
      onChange={(index) => setTabIndex(index)}
      isFitted
      isLazy
      lazyBehavior="keepMounted">
      <Flex background="#F7FAFC" pt={2} flexDir="column" w="100vw" ml={-4} justifyContent="center">
        <TabList w="100vw" justifyContent="center" px={1} border="none">
          {CHATS_TABS.map((tab, index) => (
            <Tab
              border="1px solid transparent"
              borderBottom="none"
              transition="all 0.3s ease"
              px={0}
              _selected={{
                border: '1px solid #CBD5E0 !important',
                borderWidth: '0px',
                background: 'white',
                borderBottomColor: 'transparent !important',
                borderBottomWidth: '0px !important',
              }}
              key={index}>
              <TextG12
                fontWeight={tabIndex === index ? 700 : 500}
                color={tabIndex === index ? theme.colors.blue : '#718096'}>
                {tab}
              </TextG12>
            </Tab>
          ))}
        </TabList>
      </Flex>
      <TabPanels zIndex="1000" background="white">
        <TabPanel p={0}>
          <Flex flexDir="column">
            <TextG16 fontWeight="700" pt={4} pb={2}>
              Event Chat
            </TextG16>
            <QueryInfiniteScroll
              gridGap="0"
              showItems={(event) => <EventChatCard event={event} />}
              hook={useEventRSVPs}
              endpointParams={{}}
              noItems={
                <AbsoluteCenter
                  style={{position: 'fixed'}}
                  w="100%"
                  textAlign="center"
                  color={theme.colors.grey}>
                  <Flex flexDir="column" gap={8}>
                    <Icon
                      iconName="fi-rr-comment"
                      style={{fontSize: '48px', color: theme.colors.lightGrey}}
                    />
                    <TextG18 fontWeight="700" fontSize="24px" color="black">
                      No Active Event Chats
                    </TextG18>
                    <TextG14 fontWeight="500" px={12}>
                      Here’s where you’ll find all the event chats you’re involved in.
                      <br />
                      <br />
                      You can enter a chat when you RSVP for an event, and message with others who
                      are interested and going.
                      <br />
                      <br />
                      More ways to chat are coming soon!
                    </TextG14>
                    <BlueButtonFixedWidth
                      onClick={() => navigate('/search/event')}
                      mx="auto"
                      mt={4}>
                      See Upcoming Events
                    </BlueButtonFixedWidth>
                  </Flex>
                </AbsoluteCenter>
              }
            />
          </Flex>
        </TabPanel>
        <TabPanel p={0}>
          <QueryInfiniteScroll
            gridGap="0"
            showItems={(event) => <EventChatCard event={event} closed />}
            hook={useEventRSVPs}
            endpointParams={{closed: 'true'}}
            noItems={
              <AbsoluteCenter w="90%" textAlign="center" color={theme.colors.grey}>
                <Flex flexDir="column" gap={8}>
                  <Icon
                    iconName="fi-rr-comment"
                    style={{fontSize: '48px', color: theme.colors.lightGrey}}
                  />
                  <TextG18 fontWeight="700" fontSize="24px" color="black">
                    No Closed Chats
                  </TextG18>
                  <TextG14 fontWeight="500" px={12}>
                    Here’s where you’ll find all the chats you were involved in.
                    <br />
                    <br />
                    Chats will close three hours after event ends and will appear here in the Closed
                    chats tab.
                  </TextG14>
                </Flex>
              </AbsoluteCenter>
            }
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default EventChatTab;
