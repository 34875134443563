import {Flex, Center, useTheme, AbsoluteCenter} from '@chakra-ui/react';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {BlueButtonFixedWidth} from '../../components/button';
import Icon from '../../components/Icon';
import QueryInfiniteScroll from '../../components/QueryInfiniteScroll';
import {TextG16, TextG18, TextG14, TextG12} from '../../components/typography';
import {useFetchPlaceChats} from '../../hooks/usePlaceComments';
import PlaceChatCard from './ChatCard/PlaceChatCard';

const PlaceChatTab = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Flex flexDir="column">
      <TextG16 fontWeight="700" pt={4} pb={2}>
        Place Chat
      </TextG16>
      <QueryInfiniteScroll
        gridGap="0"
        showItems={(place) => <PlaceChatCard place={place} />}
        hook={useFetchPlaceChats}
        endpointParams={{}}
        noItems={
          <AbsoluteCenter
            style={{position: 'fixed'}}
            w="100%"
            textAlign="center"
            color={theme.colors.grey}>
            <Flex flexDir="column" gap={8}>
              <Icon
                iconName="fi-rr-comment"
                style={{fontSize: '48px', color: theme.colors.lightGrey}}
              />
              <TextG18 fontWeight="700" fontSize="24px" color="black">
                No Active Place Chats
              </TextG18>
              <TextG14 fontWeight="500" px={12}>
                Here’s where you’ll find all the place chats you’re involved in.
                <br />
                <br />
                You can enter a place chat when you send a message in a place chat.
                <br />
                <br />
                More ways to chat are coming soon!
              </TextG14>
              <BlueButtonFixedWidth onClick={() => navigate('/search/place')} mx="auto" mt={4}>
                See Local Places
              </BlueButtonFixedWidth>
            </Flex>
          </AbsoluteCenter>
        }
      />
    </Flex>
  );
};

export default PlaceChatTab;
