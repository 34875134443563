import {Flex, HStack, Image, useTheme, VStack} from '@chakra-ui/react';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import ChatCard from '.';
import {Place} from '../../../types/acrticle';
import DefaultEventImage from '../../../assets/images/default_event.png';
import {TextG12, TextG14} from '../../../components/typography';

const PlaceChatCard = ({place}: {place: Place}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <ChatCard>
      <HStack align="flex-start" onClick={() => !closed && navigate(`/place/${place.id}/chat`)}>
        <Image
          borderRadius="10px"
          w="50px"
          h="50px"
          objectFit="cover"
          //fallbackSrc={DefaultEventImage}
          src={place.image_url || DefaultEventImage}
        />
        <VStack align="left" w="100%" gap="5px">
          <Flex align="center" justify="space-between">
            <TextG14 w="270px" noOfLines={1} fontWeight="500" color={theme.colors.lightBlack}>
              {place.name}
            </TextG14>
          </Flex>
          <TextG12 noOfLines={1} color={theme.colors.grey}>
            {place.street_address}
          </TextG12>
          {/* <HStack gap={2}>
            {numUnread > 0 && (
              <>
                <DotSeparator
                  color={theme.colors.rose}
                  width="4px"
                  height="4px"
                  marginLeft="0"
                  marginRight="0"
                />
                <TextG12 color={theme.colors.rose} margin="0 !important">
                  {numUnread == 1 ? 'New Message' : 'New Messsages'}
                </TextG12>
              </>
            )}
          </HStack> */}
        </VStack>
      </HStack>
    </ChatCard>
  );
};

export default PlaceChatCard;
