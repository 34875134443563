import {Box, Divider} from '@chakra-ui/react';
import React from 'react';

const ChatCard = ({children, showDivider = true, setHeight = true}) => {
  return (
    <>
      <Box
        //height={setHeight ? '70px' : 'auto'}
        mt="15px !important"
        mb="10px !important"
        userSelect="none">
        {children}
      </Box>
      {showDivider && <Divider />}
    </>
  );
};

export default ChatCard;
