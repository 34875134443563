import {AbsoluteCenter, Box, Spinner, useTheme} from '@chakra-ui/react';
import React from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import EventCard from '../../components/Articles/EventCard';
import SwiperSlider from '../../components/SwiperSlider';
import {useInfinitePublicEvents} from '../../hooks/useEvents';

const PublicEventsLandscape = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const lat = searchParams.get('lat') || undefined;
  const long = searchParams.get('long') || undefined;
  const background = searchParams.get('background') || 'grey'; // Default to 'grey' if no param exists

  const {data, fetchNextPage, hasNextPage, isFetchingNextPage} = useInfinitePublicEvents({
    walkaboutOfficial: '0',
    lat: lat,
    long: long,
  });
  // Flatten all fetched pages into a single events array
  const events = data?.pages.flatMap((page) => page.data.data) || [];
  const theme = useTheme();

  return (
    <>
      <Box
        pos="absolute"
        left="0"
        top="0"
        w="100%"
        h="100%"
        background={background.match(/^#?([a-f0-9]{6})$/i) ? `#${background}` : background}
      />
      <Box px={4} w="100%" h="100%" pos="absolute" left="0" top="0" pt="1rem">
        <SwiperSlider
          padding="4px"
          //autoHeight
          autoPlay
          pagination={false}
          breakpoints={{
            460: {
              slidesPerView: 1,
            },
            560: {
              slidesPerView: 2,
            },
            660: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
            },
            1440: {
              slidesPerView: 5,
            }
          }}
          //slidesPerView={1}
          freeMode
          dynamicBullets={false}
          items={events}
          lastCard={
            isFetchingNextPage ? (
              <Box h="260px">
                <AbsoluteCenter>
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor={theme.colors.lightGrey}
                    color={theme.colors.lightBlue}
                    size="lg"
                  />
                </AbsoluteCenter>
              </Box>
            ) : null
          }
          onReachEnd={() => {
            // Load more slides when reaching the end
            if (hasNextPage && !isFetchingNextPage) {
              fetchNextPage();
            }
          }}
          render={(item) => (
            <EventCard
              //height="270px"
              key={item.id}
              event={item}
              onClick={() => {
                navigate(`/event/${item.id}/preview`);
              }}
              isPreview
              forcePremium
            />
          )}
        />
      </Box>
    </>
  );
};

export default PublicEventsLandscape;
