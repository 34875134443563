import React from 'react';
import {Button, useTheme} from '@chakra-ui/react';
import Icon from '../../Icon';

const QuickFilter = ({filter, children, isFilterOn, onClick}) => {
  const theme = useTheme();

  return (
    <Button
      borderRadius="10px"
      borderColor={
        isFilterOn ? filter.borderColor || theme.colors.blueShade1 : theme.colors.lightestGrey
      }
      borderWidth={'1px'}
      backgroundColor={`${
        isFilterOn ? filter.backgroundColor || theme.colors.blueShade2 : 'white'
      } !important`}
      color={`${isFilterOn ? filter.color || theme.colors.blue : theme.colors.grey} !important`}
      onClick={onClick}
      w="fit-content"
      h="30px"
      p={2}
      fontSize={'10px'}
      alignItems={'center'}>
      {isFilterOn ? (
        <Icon
          style={{fontWeight: '500', margin: '4px', alignSelf: 'flex-start', fontSize: '6px'}}
          iconName={'fi fi-rr-cross'}
        />
      ) : (
        <></>
      )}
      {children}
    </Button>
  );
};

export default QuickFilter;
