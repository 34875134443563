import React, {useMemo, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Lazy, EffectFade} from 'swiper';
import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';
import FallbackImg from '../../assets/images/fallback.jpg';
import {map} from 'lodash';
import {Flex, Box, Image} from '@chakra-ui/react';
import PhotoGallery from '../PhotoGallery';
import {TextG10} from '../typography';
import Icon from '../Icon';

const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;

  .swiper-slide-duplicate-active {
    img {
      transform: scale(1.25) !important;
    }
  }

  .swiper-slide {
    img {
      transform: scale(1);
      transition: transform 5s ease-in;
    }
  }

  .swiper-slide.swiper-slide-active {
    img {
      transform: scale(1.25);
    }
  }
`;
const MediaGallery: React.FC<{media: any[]}> = ({media}) => {
  const defaultImage = media.filter((media) => media.is_default);
  const restImages = media.filter((media) => !media.is_default);
  const images = useMemo(
    () => [...map([...defaultImage, ...restImages], (media) => media.full_url)],
    [media]
  );
  const [showGallery, setShowGallery] = useState(false);

  return (
    <>
      {images && images.length <= 1 ? (
        <Image
          fallbackStrategy="onError"
          fallbackSrc={FallbackImg}
          src={images[0]}
          alt="cover image"
          position="absolute"
          width="100%"
          height="100%"
          objectFit="cover"
          zIndex="-1"
          borderBottomRadius="14px"
        />
      ) : (
        <Box zIndex={0} position="absolute" width="100%" height="100%">
          <PhotoGallery images={images} show={showGallery} setShow={setShowGallery} />

          <StyledSwiper
            virtualTranslate={true}
            fadeEffect={{
              crossFade: true,
            }}
            loop={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            lazy={true}
            effect="fade"
            speed={1000}
            spaceBetween={50}
            slidesPerView={1}
            centeredSlides={true}
            modules={[Autoplay, Lazy, EffectFade]}>
            {images.map((image, i) => (
              <SwiperSlide key={i}>
                {({isDuplicate}) => (
                  <Image
                    fallbackStrategy="onError"
                    onClick={() => setShowGallery(true)}
                    transform={isDuplicate ? 'scale(1)' : ''}
                    fallbackSrc={FallbackImg}
                    src={image}
                    alt="cover image"
                    position="absolute"
                    width="100%"
                    height="100%"
                    objectFit="cover"
                    zIndex="-1"
                  />
                )}
              </SwiperSlide>
            ))}
          </StyledSwiper>
          <Flex
            onClick={() => setShowGallery(true)}
            borderRadius="7px"
            zIndex={1}
            position="absolute"
            top="1rem"
            right=".75rem"
            width="75px"
            height="25px"
            backgroundColor="#FFFFFF"
            alignItems="center"
            px="7px"
            py="4px"
            justifyContent="space-around">
            <Icon iconName="fi fi-rr-picture" />
            <TextG10 userSelect="none" fontWeight="500">
              Gallery
            </TextG10>
          </Flex>
        </Box>
      )}
    </>
  );
};

export default MediaGallery;
