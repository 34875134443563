import {
  Box,
  Button,
  Center,
  Divider,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  useTheme,
  VStack,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, {useState} from 'react';
import Icon from '../../components/Icon';
import Modal from '../../components/Modals/Modal';
import {TextA18, TextG10, TextG12, TextG16} from '../../components/typography';
import {useInfiniteGlobalSearchWithParams} from '../../hooks/useFetchGlobalSearch';
import useGetUserLocation from '../../hooks/useUserLocation';
import {calculateAndGetDistance, getDistanceString} from '../../utils/distance';

const EventSelectModal = ({selectedEvent, setSelectedEvent, placeHolderText = true}) => {
  const [show, setShow] = useState(false);
  const [term, setTerm] = useState('');
  const userLocation = useGetUserLocation();
  const theme = useTheme();
  const {data, isLoading} = useInfiniteGlobalSearchWithParams({
    term: term.length >= 3 ? term : '',
    type: 'event',
    // lat: userLocation.data?.latitude,
    // long: userLocation.data?.longitude,
    // sort: {
    //   by: 'distance',
    //   direction: 'asc',
    // },
  });


  const searchRes = data?.pages[0].data.data;

  return (
    <>
      <Button
        onClick={() => setShow(true)}
        height="auto"
        alignItems="flex-end"
        w="100%"
        px="24px"
        py="12px"
        justifyContent="left"
        background="transparent"
        border="1px solid #E5E5E5"
        borderRadius="22.5px">
        <TextG12
          fontWeight="500"
          color={selectedEvent ? theme.colors.lightBlack : theme.colors.lightestGrey}>
          {selectedEvent?.title || 'Choose an event'}
        </TextG12>
      </Button>
      <Modal
        closeButton
        parentCallback={() => setShow(false)}
        header={
          <TextA18 fontWeight="700" mt={2}>
            {placeHolderText ? 'Where are you meeting up?' : ''}
          </TextA18>
        }
        children={
          <VStack w="100%">
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <Icon iconName="fi-rr-search" color="gray.300" />
              </InputLeftElement>

              <Input
                borderRadius="40px"
                mb={5}
                onChange={(value) => setTerm(value.target.value)}
                type="search"
                placeholder={placeHolderText ? 'Search for a Meet-up spot' : ''}></Input>
            </InputGroup>
            {isLoading ? (
              <Center h="400px">
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor={theme.colors.lightGrey}
                  color={theme.colors.lightBlue}
                  size="lg"
                />
              </Center>
            ) : (
              <VStack w="100%" maxHeight="400px" overflowY="scroll">
                {searchRes &&
                  searchRes.map((event, i) => {
                      const isSelected = selectedEvent?.id == event.id;
                      
                    return (
                      <Box key={i} w="100%">
                        <HStack
                          boxShadow={isSelected ? 'md' : 'none'}
                          transition="all 0.5s"
                          py={2}
                          borderRadius="12px"
                          border={
                            isSelected ? `1px solid ${theme.colors.blue}` : '1px solid transparent'
                          }
                          w="100%"
                          onClick={() => {
                            setShow(false);
                            setSelectedEvent(event);
                          }}>
                          {event.image_url && (
                            <Image
                              objectFit="cover"
                              minW="75px"
                              minH="75px"
                              maxH="75px"
                              maxW="75px"
                              borderRadius="full"
                              src={event.image_url}
                            />
                          )}
                          <VStack w="100%">
                            <TextG16
                              transition="all 0.5s"
                              textAlign="center"
                              isTruncated="&hellip;"
                              noOfLines={2}
                              fontWeight="500"
                              color={isSelected ? theme.colors.blue : theme.colors.lightBlack}>
                              {event.title}
                            </TextG16>
                            {userLocation.data?.latitude &&
                              userLocation.data.longitude &&
                              event?.latitude &&
                              event?.longitude && (
                                <TextG10 color={theme.colors.lightBlack}>
                                  {`${getDistanceString(
                                    calculateAndGetDistance(
                                      {
                                        latitude: Number(userLocation.data?.latitude),
                                        longitude: Number(userLocation.data?.longitude),
                                      },
                                      {
                                        latitude: Number(event.latitude),
                                        longitude: Number(event.longitude),
                                      }
                                    )
                                  )} away`}
                                </TextG10>
                              )}
                            <TextG10 color={theme.colors.grey}>
                              {dayjs.utc(event.start_date_time).local().format('MM-DD-YYYY')}
                            </TextG10>
                          </VStack>
                        </HStack>
                        <Divider />
                      </Box>
                    );
                  })}
              </VStack>
            )}
          </VStack>
        }
        show={show}
      />
    </>
  );
};

export default EventSelectModal;
