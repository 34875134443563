import React, {useEffect, useState} from 'react';
import pick from 'lodash/pick';
import {fetchUserData, login, updateActivity, updateUser} from '../endpoints/api';
import camelize from '../utils/camelize';
import {useNavigate} from 'react-router-dom';
import {deleteFromStorage, useLocalStorage} from '@rehooks/local-storage';
import {EventRSVPStatus} from './useEvent';
import {fetchToken} from '../firebase';
import {deviceDetected} from '../utils/deviceCheck';
import {Community} from '../types/community';
import * as pushService from '../pushService';
import {ActiveMembership} from '../types/membership';
import {queryClient} from '../App';

export type SuperFilters = {
  id: number;
  user_id: number;
  topic_ids: string[];
  lifestyle_group_ids: string[];
  is_deleted: number;
};

export interface User {
  id: number;
  name: string;
  surname: string;
  email: string;
  phone: string;
  pointsTotal: number;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  mailingAddress: string;
  aboutMe: string;
  lifestyleGroups: Record<string, string>[];
  topics: Record<string, string>[];
  smsEnabled: boolean;
  pushEnabled: boolean;
  emailEnabled: boolean;
  profilePhotoUrl: string;
  community: Record<string, string>;
  checkIns: Record<string, string>[];
  referralCode: string;
  isFenton: boolean;
  superfilters: SuperFilters;
  hasEarlyAccess: boolean;
  handle: string;
  atm_rsvp_status?: EventRSVPStatus;
  communities?: Community[];
  is_walkabout_employee?: boolean;
  pushEnabledUpdatedAt?: string;
  isMobileDebugEnabled?: boolean;
  isMobileDebugAvailable?: boolean;
  createdAt?: string;
  hasActiveMembership?: boolean;
  activeMemberships?: ActiveMembership[];
  activeMembershipPlan?: any;
  membershipActivities?: any;
  roles?: any;
}

/**
 * This is the API provided by useAuth()
 */
interface AuthAPI {
  user?: User;
  isUserAuthenticated: boolean;
  reFetchUserData: () => Promise<void>;
  // returns the default page url for the newly logged in user
  login(email: string, password: string): Promise<string | void>;
  loginWithToken(token: string): void;
  logout(): void;
}

const AuthContext = React.createContext<AuthAPI>({} as AuthAPI);

export const USER_TOKEN = 'user_token';
export const USER_DELETED_AT = 'user_deleted_at';

const DEFAULT_PAGE = '/home';

export const AuthProvider = ({children}) => {
  const [user, setUser] = useState<User>();
  const [userToken, setUserToken] = useLocalStorage<string>(USER_TOKEN);
  const [userDeletedAt, setUserDeletedAt] = useLocalStorage<string | null>(USER_DELETED_AT);
  const navigate = useNavigate();

  const setDeviceToken = async () => {
    const device_token = await fetchToken();
    if (device_token)
      await updateUser({device_token: device_token, device_platform: deviceDetected()});
  };

  // useEffect(() => {
  //   if (userToken) {
  //     console.log('setting device token');
  //     console.log(userToken);
  //     setDeviceToken().catch(console.error);
  //   }
  // }, [userToken]);

  const loginHandler = async (email: string, password: string) => {
    const {data: token, status} = await login({
      email,
      password,
    });
    if (status === 200) {
      // This causes a 401 (unauthicated) error on dev because the updateUser call happens before the user token is actually set.
      // Moved to useEffect instead to fire only when userToken is actually set.
      // const device_token = await fetchToken();
      // if (device_token) await updateUser({device_token: device_token, device_platform: deviceDetected()});
      setUserToken(token);
      return DEFAULT_PAGE;
    }
  };

  const loginWithTokenHandler = (token) => {
    if (!token) throw new Error('Invalid token.');
    setUserToken(token);
  };

  const logoutHandler = async () => {
		// clearing the device token on logout in the user table
		await updateUser({device_token: ""});
    deleteFromStorage(USER_TOKEN);

		// this is the user object that is stored locally for auth-sessions. On logout it needs to be cleared -> undefined/null 
    setUser(undefined);
    const message = {
      type: 'logout',
    };
    if (window?.['ReactNativeWebView']) {
      window['ReactNativeWebView'].postMessage(JSON.stringify(message));
    }
    queryClient.clear();
    navigate('/auth');
  };

  const loadUserProfile = () => {
    return fetchUserData()
      .then(({status, data}) => {
        if (status === 200 && data.deleted_at === null) {
          setUserFromPayload(data);
          setUserDeletedAt(data.deleted_at);
        } else {
          console.error(status, data);
          logoutHandler();
        }
      })
      .catch(() => {
        deleteFromStorage(USER_TOKEN);
        setUser(undefined);
      });
  };

  const setUserFromPayload = (payload) => {
    const p = camelize(payload);
    const newUser = {
      ...pick(p, [
        'id',
        'name',
        'surname',
        'email',
        'phone',
        'email',
        'pointsTotal',
        'address',
        'address2',
        'city',
        'state',
        'zip',
        'aboutMe',
        'lifestyleGroups',
        'topics',
        'smsEnabled',
        'pushEnabled',
        'emailEnabled',
        'profilePhotoUrl',
        'community',
        'mailingAddress',
        'checkIns',
        'referredBy',
        'referralCode',
        'isFenton',
        'superfilters',
        'hasEarlyAccess',
        'handle',
        'communities',
        'is_walkabout_employee',
        'pushEnabledUpdatedAt',
        'isMobileDebugEnabled',
        'isMobileDebugAvailable',
        'createdAt',
        'hasActiveMembership',
        'activeMemberships',
        'activeMembershipPlan',
        'membershipActivities',
        'roles',
      ]),
    };
    if (window?.['ReactNativeWebView']) {
      window?.['ReactNativeWebView']?.postMessage(JSON.stringify({type: 'user', user: newUser}));
    }
    setUser(newUser);
    redirectIfMissingData(newUser);
  };

  useEffect(() => {
    if (userToken && !user) {
      loadUserProfile();
    }
  }, [userToken, user]);

  useEffect(() => {
    let interval = setInterval(() => {
      if (user && document.visibilityState === 'visible') updateActivity();
    }, 120000);

    const switched = () => {
      if (user && document.visibilityState === 'visible') {
        interval = setInterval(() => {
          updateActivity();
        }, 120000);
      } else clearInterval(interval);

      setTimeout(() => {
        if (user && document.visibilityState === 'hidden') {
          updateActivity();
        }
      }, 0);
    };
    const closed = () => {
      if (user) updateActivity();
    };

    // tab switched
    document.addEventListener('visibilitychange', switched);

    // app closed
    window.addEventListener('unload', closed);

    return () => {
      clearInterval(interval);
      document.removeEventListener('visibilitychange', switched);
      window.removeEventListener('unload', closed);
    };
  }, [user]);

  useEffect(() => {
    if (window['ReactNativeWebView'] && user) {
      const message = {
        type: 'user',
        user,
      };
      window['ReactNativeWebView'].postMessage(JSON.stringify(message));
      if (user.pushEnabled) {
        pushService.pushPermissionRequest();
      }
    }
  }, [window['ReactNativeWebView'], user]);

  useEffect(() => {
    if (window['ReactNativeWebView'] && userToken) {
      const message = {
        type: 'token',
        token: userToken,
      };
      window['ReactNativeWebView'].postMessage(JSON.stringify(message));
    }
  }, [window['ReactNativeWebView'], userToken]);

  const auth: AuthAPI = {
    user,
    isUserAuthenticated: !!userToken,
    reFetchUserData: loadUserProfile,
    login: loginHandler,
    loginWithToken: loginWithTokenHandler,
    logout: logoutHandler,
  };

  const redirectIfMissingData = (user) => {
    const lifestyleGoalRoute = '/register/goals-interests';
    const topicsRoute = '/register/goals-interests';
    const usernameRoute = '/register/username';

    if ([lifestyleGoalRoute, topicsRoute, usernameRoute].includes(window.location.pathname)) return;

    if (
      !window.location.pathname.startsWith('/profile') &&
      !window.location.pathname.includes('explainer') &&
      !window.location.pathname.includes('/profile-pic')
    ) {
      if (!user.handle || user.handle.length < 1) {
        return navigate(usernameRoute);
      }

      if (user.lifestyleGroups.length < 1) {
        return navigate(lifestyleGoalRoute);
      }

      if (user.topics.length < 1) {
        return navigate(topicsRoute);
      }
    }
  };

  const loadingProfile = userToken && !user;

  return (
    <AuthContext.Provider value={auth}>{loadingProfile ? null : children}</AuthContext.Provider>
  );
};

export default function useAuth() {
  return React.useContext(AuthContext);
}
