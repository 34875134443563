import React, {useState, useEffect} from 'react';
import {
  Box,
  Button,
  Center,
  Container,
  FormControl,
  FormLabel,
  Input,
  Select,
  Spinner,
  Switch,
  Textarea,
  useTheme,
  VStack,
} from '@chakra-ui/react';
import {useFetchQRDetails} from '../../hooks/useQRCodeDetails';
import {useNavigate, useParams} from 'react-router-dom';
import {TextA12, TextA20, TextG14} from '../../components/typography';
import {BlueButton} from '../../components/button';
import PlaceSelectModal from '../CreateMeetup/PlaceSelectModal';
import {Event, Place} from '../../types/acrticle';
import {editQrCode, fetchEvent, fetchPartner, fetchPlace} from '../../endpoints/api';
import PartnerSelectModal from '../PartnerSelectModal';
import EventSelectModal from '../EventSelectModal';
import useGetUserLocation from '../../hooks/useUserLocation';
import {omit} from 'lodash';
import {SpinnerLoader} from '../../components/Loader';
import {ToastMessage} from '../../components/toast';
import {useQueryClient} from '@tanstack/react-query';
import useAuth from '../../hooks/useAuth';

enum CodeClassification {
  TABLE_CODE = 'Table Code',
  BATHROOM_CODE = 'Bathroom Code',
  FLYER = 'Flyer',
  HANDOUT = 'Handout',
  GENERIC = 'Generic',
}

const stripHtmlTags = (html: string): string => {
  const div = document.createElement('div');
  div.innerHTML = html;
  return div.textContent || div.innerText || '';
};

const EDITOR_ROLES = ['ADMIN', 'QR_EDITOR'];

// Update the QRCodeData interface
interface QRCodeData {
  id: string;
  url: string;
  description: string;
  partner: string;
  place: string;
  event: string;
  campaign: string;
  is_active: boolean;
  //metadata?: string;
  code_classification: CodeClassification;
}
const QREditPage = () => {
  const queryClient = useQueryClient();
  const [qrData, setQrData] = useState<QRCodeData>({
    id: '',
    url: '',
    description: '',
    partner: '',
    place: '',
    event: '',
    campaign: '',
    is_active: false,
    //metadata: '',
    code_classification: CodeClassification.GENERIC,
  });
  const {user} = useAuth();
  const {slug} = useParams();
  const {data, isLoading, isError} = useFetchQRDetails(slug);
  //const userLocation = useGetUserLocation();
  const [selectedPlace, setSelectedPlace] = useState<Place>();
  const [selectedEvent, setSelectedEvent] = useState<Event>();
  const [selectedPartner, setSelectedPartner] = useState<any>();
  const theme = useTheme();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [partnerLoading, setPartnerLoading] = useState(true);
  const [placeLoading, setPlaceLoading] = useState(true);
  const [eventLoading, setEventLoading] = useState(true);

  useEffect(() => {
    if (user) {
      const canEdit = user.roles?.some((role) => EDITOR_ROLES.includes(role.name));

      if (!canEdit) {
        navigate('/home');
      }
    }
  }, [user]);

  // Set initial QR code data
  useEffect(() => {
    setLoading(true);
    if (data?.data?.qr_code) {
      const qr_code: any = omit(data?.data?.qr_code, ['metadata']);

      setQrData({
        ...qr_code,
        description: stripHtmlTags(qr_code.description),
      });

      if (qr_code.place_id) {
        fetchPlace({id: qr_code.place_id}).then((res) => {
          setSelectedPlace(res.data);
          setPlaceLoading(false);
        });
      } else {
        setPlaceLoading(false);
      }

      if (qr_code.event_id) {
        fetchEvent({id: qr_code.event_id}).then((res) => {
          setSelectedEvent(res.data);
          setEventLoading(false);
        });
      } else {
        setEventLoading(false);
      }

      if (qr_code.partner_id) {
        fetchPartner({id: qr_code.partner_id}).then((res) => {
          setSelectedPartner(res.data);
          setPartnerLoading(false);
        });
      } else {
        setPartnerLoading(false);
      }
    }
    setLoading(false);
  }, [data, isLoading]);

  // Set place_id on selected change
  useEffect(() => {
    setQrData((prev) => ({
      ...prev,
      place_id: selectedPlace?.id || '',
    }));
  }, [selectedPlace]);

  // Set event_id on selected change
  useEffect(() => {
    setQrData((prev) => ({
      ...prev,
      event_id: selectedEvent?.id || '',
    }));
  }, [selectedEvent]);

  // Set partner_id on selected change
  useEffect(() => {
    setQrData((prev) => ({
      ...prev,
      partner_id: selectedPartner?.id || '',
    }));
  }, [selectedPartner]);

  // Handle input change
  const handleChange = (key: keyof QRCodeData, value: string | boolean) => {
    setQrData((prev) => ({...prev, [key]: value}));
  };

  // Save changes
  const handleSave = async () => {
    try {
      await editQrCode(qrData.id, {...qrData}); // Wait for the async operation to complete

      ToastMessage({
        showStatusIcon: true,
        status: 'success',
        text: 'QR code updated successfully.',
      });

    } catch (error) {
      ToastMessage({
        showStatusIcon: true,
        status: 'error',
        text: 'Failed to save QR code.',
      });
    }
    finally {
      // Invalidate the query to refetch updated QR code details
      queryClient.invalidateQueries(['qr-code-details', slug]);
    }
  };

  if (isLoading || loading || !user) {
    return (
      <Center h="100vh">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );
  }

  return (
    <Container>
      <Box maxW="600px" mx="auto" py={8}>
        <VStack pb={6}>
          <TextA20>Edit QR Code</TextA20>
          {data?.data?.qr_code && (
            <TextA12 mt="0 !important">{`UUID: ${data?.data?.qr_code.uuid}`}</TextA12>
          )}
          {data?.data?.qr_code && (
            <TextA12 mt="0 !important">{`Slug: ${data?.data?.qr_code.slug}`}</TextA12>
          )}
        </VStack>
        <FormControl mb={4}>
          <FormLabel>URL</FormLabel>
          <Input
            borderRadius="22px"
            value={qrData.url}
            onChange={(e) => handleChange('url', e.target.value)}
          />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Description</FormLabel>
          <Textarea
            borderRadius="22px"
            value={qrData.description}
            onChange={(e) => handleChange('description', e.target.value)}
          />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel display="flex" alignItems="center">
            <TextG14 color={theme.colors.lightBlack}>Select Partner</TextG14>
          </FormLabel>
          {partnerLoading ? (
            <SpinnerLoader />
          ) : (
            <>
              <PartnerSelectModal
                placeHolderText={false}
                selectedPartner={selectedPartner}
                setSelectedPartner={setSelectedPartner}
              />

              {selectedPartner && <TextG14>Partner id: {selectedPartner.id}</TextG14>}
              <BlueButton mt={2} onClick={() => setSelectedPartner(undefined)}>
                Remove Partner
              </BlueButton>
            </>
          )}
        </FormControl>
        <FormControl mb={4}>
          <FormLabel display="flex" alignItems="center">
            <TextG14 color={theme.colors.lightBlack}>Select Place</TextG14>
          </FormLabel>
          {placeLoading ? (
            <SpinnerLoader />
          ) : (
            <>
              <PlaceSelectModal
                placeHolderText={false}
                selectedPlace={selectedPlace}
                setSelectedPlace={setSelectedPlace}
              />

              {selectedPlace && <TextG14>Place id: {selectedPlace.id}</TextG14>}
              <BlueButton mt={2} onClick={() => setSelectedPlace(undefined)}>
                Remove Place
              </BlueButton>
            </>
          )}
        </FormControl>
        <FormControl mb={4}>
          <FormLabel display="flex" alignItems="center">
            <TextG14 color={theme.colors.lightBlack}>Select Event</TextG14>
          </FormLabel>
          {eventLoading ? (
            <SpinnerLoader />
          ) : (
            <>
              <EventSelectModal
                placeHolderText={false}
                selectedEvent={selectedEvent}
                setSelectedEvent={setSelectedEvent}
              />

              {selectedEvent && <TextG14>Event id: {selectedEvent.id}</TextG14>}
              <BlueButton mt={2} onClick={() => setSelectedEvent(undefined)}>
                Remove Event
              </BlueButton>
            </>
          )}
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Campaign</FormLabel>
          <Input
            borderRadius="22px"
            value={qrData.campaign}
            onChange={(e) => handleChange('campaign', e.target.value)}
          />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Code Classification</FormLabel>
          <Select
            borderRadius="22px"
            value={qrData.code_classification}
            onChange={(e) =>
              handleChange('code_classification', e.target.value as CodeClassification)
            }>
            {Object.values(CodeClassification).map((classification) => (
              <option key={classification} value={classification}>
                {classification}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl display="flex" alignItems="center" mb={4}>
          <FormLabel mb={0}>Is Active</FormLabel>
          <Switch
            colorScheme="green"
            isChecked={qrData.is_active}
            onChange={(e) => handleChange('is_active', e.target.checked)}
          />
        </FormControl>
        {/* <FormControl mb={4}>
          <FormLabel>Meta Data</FormLabel>
          <Textarea
            borderRadius="22px"
            value={qrData.metadata}
            onChange={(e) => handleChange('metadata', e.target.value)}
          />
        </FormControl> */}
        <BlueButton mr={4} onClick={handleSave} isLoading={isLoading}>
          Save
        </BlueButton>
        <Button onClick={() => navigate(`/q/${slug}`)} borderRadius="22px" variant="outline">
          Cancel
        </Button>
      </Box>
    </Container>
  );
};

export default QREditPage;
