import {Box, Center, Flex, Spinner, TabPanel, TabPanels, Tabs, useTheme} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import {isIphone} from '../../utils/deviceCheck';
import ChatHeader from './ChatHeader/PlaceChatHeader';
import {CHAT_TABS} from './ChatHeader/ChatTabs';
import FeaturedTab from './FeaturedTab';
import RepliesTab from './RepliesMentionsTab';
import ThreadTab from './ThreadTab';
// import {
//   useAddCommentImage,
//   useCreateCommunityComment,
//   useDeleteCommentImage,
//   useDeleteCommunityComment,
//   useFetchCommunity,
//   useFetchCommunityChatUsers,
//   useFetchRepliesAndMentions,
//   useHelpfulCommunityComment,
//   useInfiniteCommunityComments,
//   useInfiniteFeaturedCommunityComments,
//   useLastChatVisit,
//   useReportCommunityComment,
//   useUpdateCommunityComment,
// } from '../../hooks/useCommunityComments';
import {getImage} from '../../pages/Profile/Edit/CropImage';
import {ChatMedia} from './ChatComment';
import {useFetchPlace} from '../../hooks/useFetchPlace';
import {
  useAddCommentImage,
  useCreatePlaceComment,
  useDeleteCommentImage,
  useDeletePlaceComment,
  useFetchPlaceChatUsers,
  useFetchRepliesAndMentions,
  useHelpfulPlaceComment,
  useInfiniteFeaturedPlaceComments,
  useInfinitePlaceComments,
  useReportPlaceComment,
  useUpdatePlaceComment,
} from '../../hooks/usePlaceComments';
import { TextG14 } from '../typography';
import Icon from '../Icon';

const fetchCommentsHook = useInfinitePlaceComments;

const PlaceChat = () => {
  const {placeId, tab} = useParams();
  const {user} = useAuth();
  const fetchPlace = useFetchPlace({id: Number(placeId)});
  const place = fetchPlace.data?.data;
  const theme = useTheme();
  let [searchParams, setSearchParams] = useSearchParams();
  const defaultTab = CHAT_TABS.indexOf(decodeURIComponent(searchParams.get('tab') || 'Thread'));
  const [headerHeight, setHeaderHeight] = useState(0);
  const [hideHeader, setHideHeader] = useState(false);
  const [tabIndex, setTabIndex] = useState(defaultTab == -1 ? 0 : defaultTab);

  //const lastChatVisit = useLastChatVisit();

  const addPlaceComment = useCreatePlaceComment();
  const updatePlaceComment = useUpdatePlaceComment();
  const deletePlaceComment = useDeletePlaceComment();
  const reportPlaceComment = useReportPlaceComment();
  const helpfulPlaceComment = useHelpfulPlaceComment();
  const addCommentImage = useAddCommentImage();
  const deleteCommentImage = useDeleteCommentImage();

  const addComment = async (content: string, parent_id?: string, imageURLs?: Array<any>) => {
    if (content.length > 0) {
      const comment = await addPlaceComment.mutateAsync({
        placeId: place.id,
        content,
        parent_id,
        user,
        hasImage: imageURLs && imageURLs.length > 0,
      });

      if (imageURLs && imageURLs.length > 0 && comment) {
        imageURLs.map(async (media) => {
          const croppedImage = await getImage(media, null);

          if (placeId && croppedImage) {
            await addCommentImage.mutateAsync({
              formData: croppedImage,
              target_id: placeId,
              comment_id: comment.data.id,
            });
          }
        });
      }
    }
  };

  const updateComment = async (
    commentId: string,
    content: string,
    parent_id?: string,
    imageURLs?: Array<any>,
    mediaToDelete?: ChatMedia[]
  ) => {
    if (content.length > 0) {
      await updatePlaceComment.mutateAsync({
        placeId: place.id,
        commentId,
        content,
      });

      if (imageURLs && imageURLs.length > 0) {
        imageURLs.map(async (media) => {
          const croppedImage = await getImage(media, null);
          if (placeId && croppedImage && commentId) {
            await addCommentImage.mutateAsync({
              formData: croppedImage,
              target_id: placeId,
              comment_id: commentId,
            });
          }
        });
      }

      if (mediaToDelete && mediaToDelete.length > 0) {
        mediaToDelete.map(async (media) => {
          if (media.id) {
            await deleteCommentImage.mutateAsync({mediaId: media.id});
          }
        });
      }
    }
  };

  const deleteComment = async (commentId: string) => {
    await deletePlaceComment.mutateAsync({
      placeId: place.id,
      commentId,
    });
  };

  const reportComment = async (commentId: string) => {
    await reportPlaceComment.mutateAsync({
      placeId: place.id,
      commentId,
    });
  };

  const helpfulComment = async (commentId: string) => {
    await helpfulPlaceComment.mutateAsync({
      placeId: place.id,
      commentId,
    });
  };

  // to fix issue with overscroll on iOS
  useEffect(() => {
    if (isIphone) document.body.style.overflow = 'hidden';
    return () => {
      if (isIphone) document.body.style.overflow = 'unset';
    };
  }, []);

  //   useEffect(() => {
  //     let intervalId;
  //     if (place && place.id) {
  //       lastChatVisit.mutateAsync({communityId: place.id});
  //       intervalId = setInterval(() => {
  //         lastChatVisit.mutateAsync({communityId: place.id});
  //       }, 5000);
  //     }
  //     return () => {
  //       if (intervalId) clearInterval(intervalId);
  //     };
  //   }, [place]);

  useEffect(() => {
    setSearchParams({['tab']: CHAT_TABS[tabIndex]}, {replace: true});
  }, [tabIndex]);

  window.addEventListener('keyboardDidShow', () => {
    setHideHeader(true);
    setHeaderHeight(0);
  });

  window.addEventListener('keyboardDidHide', () => {
    setHideHeader(false);
  });

  if (!place || !user)
    return (
      <Center h="100vh">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );

  return (
    <Box>
      <Tabs
        outline={'none !important'}
        index={tabIndex}
        onChange={(index) => setTabIndex(index)}
        isLazy
        lazyBehavior="keepMounted">
        <Flex>
          {!hideHeader && (
            <ChatHeader
              addComment={addComment}
              updateComment={updateComment}
              deleteComment={deleteComment}
              reportComment={reportComment}
              helpfulComment={helpfulComment}
              place={place}
              setHeaderHeight={setHeaderHeight}
              tabIndex={tabIndex}
            />
          )}

          <TabPanels zIndex="auto" background="white">
            <TabPanel p={0}>
              <ThreadTab
                name={place.name}
                articleId={place.id}
                isRSVPd={true}
                fetchUsersHook={useFetchPlaceChatUsers} //event ? useFetchChatUsers : useFetchCommunityChatUsers
                addComment={addComment}
                updateComment={updateComment}
                deleteComment={deleteComment}
                reportComment={reportComment}
                helpfulComment={helpfulComment}
                headerHeight={headerHeight}
                fetchCommentsHook={fetchCommentsHook}
                showUserRSVP={false}
                noMessageComponent={
                  <Flex flexDir="column" gap={8}>
                    <Icon iconName="fi-rr-comment" style={{fontSize: '48px', color: '#F4F4F4'}} />
                    <TextG14 fontWeight="500">
                      Welcome to this place chat just for locals!
                      <br />
                      <br />
                      Make the most out of your experience by asking questions, sharing your
                      excitement about the place, or planning meet-ups with like-minded locals on
                      Walkabout.
                    </TextG14>
                  </Flex>
                }
              />
            </TabPanel>
            <TabPanel p={0}>
              <RepliesTab
                articleId={place.id}
                fetchRepliesHook={useFetchRepliesAndMentions}
                headerHeight={headerHeight}
              />
            </TabPanel>
            <TabPanel p={0}>
              <FeaturedTab
                fetchUsersHook={useFetchPlaceChatUsers}
                name={place.name}
                isRSVPd={true}
                addComment={addComment}
                updateComment={updateComment}
                deleteComment={deleteComment}
                reportComment={reportComment}
                helpfulComment={helpfulComment}
                fetchFeaturedCommentsHook={useInfiniteFeaturedPlaceComments}
                articleId={place.id}
                headerHeight={headerHeight}
              />
            </TabPanel>
          </TabPanels>
        </Flex>
      </Tabs>
    </Box>
  );
};

export default PlaceChat;
