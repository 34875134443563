import React, {useEffect, useMemo} from 'react';
import {Flex, HStack, Spacer, useTheme} from '@chakra-ui/react';
import {TextG10, TextG14} from '../../typography';
import {generatePath, Link, useParams} from 'react-router-dom';
import {ROUTES} from '../../../constants/routes';
import {Place} from '../../../types/acrticle';
import {calculateAndGetDistance, getDistanceString} from '../../../utils/distance';
import {usePlaceTypes} from '../../../hooks/usePlaceTypes';
import CardInterestTags from '../CardInterestTags';
import {useTrackVisibility} from 'react-intersection-observer-hook';
import useVisibleArticles from '../../../hooks/useVisible';
import {useOffers} from '../../../hooks/useOffers';
import useGetUserLocation from '../../../hooks/useUserLocation';
import OffersCardBanner from '../../OffersCardBanner';
import PremiumCard from '../../Card/PremiumCard';
import {storeScrollPositionInSession} from '../../../utils/scroll';
import CardBadge from '../../CardBadge';
import PointBoostBadge from './PointBoostBadge';

const PlaceCard = ({
  place,
  leftTopItem,
  rightTopItem,
  height = 'fit-content',
  modal,
  isSlide = false,
  isPreview = false,
}: {
  place?: Place;
  leftTopItem?: any;
  rightTopItem?: any;
  height?: string;
  modal?: boolean;
  isSlide?: boolean;
  isPreview?: boolean;
}) => {
  if (!place) return null;
  const theme = useTheme();
  const {placeTypesLoading, getPlaceTypeFromId} = usePlaceTypes(!isPreview);
  const placeTypeName = getPlaceTypeFromId(place?.place_type_id)?.display_name;
  const {pathname} = useParams();
  const isProduction = process.env.NODE_ENV === 'production';
  const isPartner = place.partner || place.partner_id;
  let userLocation;
  if (!isPreview) {
    userLocation = useGetUserLocation();
  }
  const distance = useMemo(() => {
    if (place && userLocation) {
      return calculateAndGetDistance(
        {
          latitude: Number(userLocation.data?.latitude),
          longitude: Number(userLocation.data?.longitude),
        },
        {
          latitude: Number(place.latitude),
          longitude: Number(place.longitude),
        }
      );
    }
    return 0;
  }, [userLocation?.data, place]);

  const [ref, {wasEverVisible, isVisible}] = useTrackVisibility();
  const {addVisibleArticle} = useVisibleArticles();

  useEffect(() => {
    if (wasEverVisible && place?.id.toString()) {
      addVisibleArticle({id: place.id.toString(), type: 'place'});
    }
  }, [wasEverVisible]);

  const image = useMemo(() => {
    if (place) {
      return place.image_url;
    }
  }, [place]);

  const fetchOffers = useOffers({
    articleType: 'placeId',
    articleId: place.id.toString(),
    enabled: !isPreview && isVisible,
  });

  const activeOffers = useMemo(() => {
    if (fetchOffers && fetchOffers.offers && fetchOffers.offers.length) {
      return <OffersCardBanner fullCard={true} offers={fetchOffers.offers} />;
    }
  }, [fetchOffers, theme]);

  return (
    <Link
      style={{width: '100%'}}
      to={
        isPreview
          ? ''
          : modal
          ? {pathname, search: `?id=${place.id.toString()}&articleType=place`}
          : generatePath(ROUTES.PLACE, {
              placeId: place.id.toString(),
            })
      }
      onClick={() => storeScrollPositionInSession()}
      ref={ref}>
      <PremiumCard
        article={place}
        isSlide={isSlide}
        height={height}
        coverImage={image}
        leftTopItem={leftTopItem}
        rightTopItem={rightTopItem}
        childPaddingX={4}>
        {isPartner ? (
          <CardBadge label="WALKABOUT PARTNER" w="145px" top="-12px" marginLeft="auto" />
        ) : null}
        <Flex mt={isPartner ? '-10px' : 0} py={1} alignItems="center" textAlign="center">
          {placeTypeName && (
            <TextG10 color={theme.colors.grey} noOfLines={null}>
              {placeTypeName}
            </TextG10>
          )}
          {activeOffers}
        </Flex>
        <HStack justifyContent="space-between" align="center">
          <TextG14
            isTruncated="&hellip;"
            noOfLines={null}
            fontWeight={700}
            color={theme.colors.black}>
            {place.name || place.title}
          </TextG14>
          {(!place.topics_of_interest ||
            place.topics_of_interest.filter((topic) => !topic.name.includes('Context:')).length ==
              0) &&
          distance ? (
            <TextG10 sx={{wordWrap: 'normal !important'}} color={theme.colors.lightBlack}>
              {getDistanceString(distance)}
            </TextG10>
          ) : null}
        </HStack>
        <PointBoostBadge flexDir="row" article={place} />
        <Flex mt={1} justifyContent="space-between" align="center">
          {place.topics_of_interest && place.topics_of_interest.length > 0 ? (
            <CardInterestTags
              autoScroll={true}
              tagsVisible={isVisible}
              onlyShowMatched={false}
              topicsOfInterest={place.topics_of_interest}
            />
          ) : null}

          <Spacer />
          {place.topics_of_interest &&
          place.topics_of_interest.filter((topic) => !topic.name.includes('Context:')).length > 0 &&
          distance ? (
            <TextG10 sx={{wordWrap: 'normal !important'}} color={theme.colors.lightBlack}>
              {getDistanceString(distance)}
            </TextG10>
          ) : null}
        </Flex>
      </PremiumCard>
    </Link>
  );
};

PlaceCard.defaultProps = {
  showEndorsements: true,
};

export default PlaceCard;
