import {useInfiniteQuery, useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {
  fetchChatUsers,
  fetchEventInvites,
  fetchEventRSVP,
  fetchEvents,
  fetchNewEventMessages,
  fetchPublicEvents,
  lastChatVisit,
} from '../endpoints/api';

interface FetchEventsOptions {
  page?: string;
  communityId?: string;
}

export const useFetchEvents = ({page = '1', communityId}: FetchEventsOptions) => {
  return useQuery(['events', {page, communityId}], () =>
    fetchEvents({page, communityId, cancelReq: null})
  );
};

export const useInfiniteEvents = ({communityId}: FetchEventsOptions) => {
  return useInfiniteQuery(
    ['infinite-events', {communityId}],
    ({pageParam = '1'}) => fetchEvents({page: pageParam, communityId, cancelReq: null}),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.current_page !== lastPage.data.last_page) {
          return lastPage.data.current_page + 1;
        }
      },
    }
  );
};

export const useEvents = ({page, communityId}: FetchEventsOptions) => {
  const fetchEvents = useFetchEvents({page, communityId});

  return {events: fetchEvents.data?.data.data, eventsIsLoading: fetchEvents.isLoading};
};

export const useEventRSVPs = ({closed}: {closed: string}) => {
  return useInfiniteQuery(
    ['infinite-events-rsvps', {closed}],
    ({pageParam = '1'}) => fetchEventRSVP({page: pageParam, closed}),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.current_page !== lastPage.data.last_page) {
          return lastPage.data.current_page + 1;
        }
      },
    }
  );
};

export const useNewEventChatMessages = () => {
  return useQuery(['new_events_messages'], () => fetchNewEventMessages(), {refetchInterval: 10000});
};

export const useLastChatVisit = () => {
  const queryClient = useQueryClient();
  return useMutation(({eventId}: {eventId: number}) => lastChatVisit({eventId}), {
    onSettled: (newComment, error, variables, context) => {
      queryClient.invalidateQueries(['new_events_messages']);
    },
  });
};

export const useFetchChatUsers = (eventId: number) => {
  return useInfiniteQuery(
    ['infinite_event_users', eventId],
    ({pageParam = 1}) => fetchChatUsers({eventId, page: pageParam}),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage?.data.current_page !== lastPage?.data.last_page) {
          return lastPage?.data.current_page + 1;
        }
      },
      enabled: typeof eventId === 'number',
    }
  );
};

export const useFetchEventInvites = () => {
  return useQuery(['event_invites'], () => fetchEventInvites());
};

export const useFetchPublicEvents = () => {
  return useQuery(['public_events'], () => fetchPublicEvents({}));
};

export const useInfinitePublicEvents = ({
  walkaboutOfficial,
  lat,
  long,
}: {
  walkaboutOfficial?: string;
  lat?: string;
  long?: string;
}) => {
  return useInfiniteQuery(
    ['infinite-events', {walkaboutOfficial}],
    ({pageParam = 1}) => fetchPublicEvents({page: pageParam, walkaboutOfficial, lat, long}),
    {
      getNextPageParam: (lastPage) => {
        const {current_page, last_page} = lastPage.data;
        return current_page < last_page ? current_page + 1 : undefined;
      },
    }
  );
};
