import {Box, useTheme, Flex, Image, AbsoluteCenter} from '@chakra-ui/react';
import React, {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import Icon from '../../components/Icon';
import {TextG10, TextG12, TextG14} from '../../components/typography';
import BenefitCard from './BenefitCard';
import EventImage from '../../assets/images/member_events.jpg';
import useAuth from '../../hooks/useAuth';
import {addExternalParam} from '../../utils/externalLink';

const MEMBER_SERVICE_NUM = '16199493059';
const STRIPE_SETTINGS_URL = 'https://billing.stripe.com/p/login/7sI6qU8mQgxPf8A8ww';

const MembershipAbout = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {user} = useAuth();

  const handleSendSMS = () => {
		if (window['ReactNativeWebView']) {
			window['ReactNativeWebView'].postMessage(JSON.stringify({type: 'sms', number: MEMBER_SERVICE_NUM}));
		} else {
    const smsUrl = `sms:${MEMBER_SERVICE_NUM}`;
    window.location.href = smsUrl;
		}
  };


  const membershipButtonData = useMemo(
    () => [
      {
        title: 'Member Events & Experiences',
        description:
          'Come hang out at special Walkabout Official Events & Community Partner Experiences.',
        icon: {name: 'fi-rr-glass-cheers', color: theme.colors.rose},
        onClick: () =>
          navigate(
            '/standalone-search/event?passTypes=1%2C2%2C3&filterTitle=Member%20Events&back=1&by=start_date_time&direction=asc'
          ),
      },
      // {
      //   title: 'Locals-Only Discounts',
      //   description:
      //     'Check out the special locals-only discounts available to you around town. New discounts added often.',
      //   icon: {name: 'fi-rr-receipt', color: '#F9AC39'},
      //   onClick: () => navigate('/my-stuff?tab=Discounts'),
      // },
      // {
      //   title: 'Secret Menu Items',
      //   description:
      //     'See the list of Secret Menu items hosted by many of the Local Businesses partnered with Walkabout.',
      //   icon: {name: 'fi-rr-incognito', color: theme.colors.grey2}, //'#5DE02F'
      //   onClick: () => {}, // coming soon, so no navigation
      //   comingSoon: true,
      // },
      {
        title: 'Marketplace',
        description:
          'Special Items Around Town (Use your passes to get these special local items from our partners around town)',
        icon: {name: 'fi-rr-shop', color: '#29B9D9'},
        onClick: () => navigate('/search/offer'),
      },
      {
        title: 'Group Chat',
        description:
          'Chat and connect with other locals who are on Walkabout so you can make plans or ask questions.',
        icon: {name: 'fi-rr-comment', color: '#7D3EEE'},
        onClick: () => navigate('/lifestyle/chat'),
      },
      {
        title: 'Member Support',
        description:
          'Ask questions, reach out about your experience, or suggest the next great member event.',
        icon: {name: 'fi-rr-man-head', color: '#27BA85'},
        onClick: handleSendSMS,
      },
      {
        title: 'Subscription Settings',
        description: 'View your subscription and billing information.',
        icon: {name: 'fi-rr-calculator', color: '#272929'},
        onClick: () => {
          if (user && user.email) {
            window.open(
              addExternalParam(
                `${STRIPE_SETTINGS_URL}?prefilled_email=${encodeURIComponent(user.email)}`
              ),
              '_blank',
              'noopener,noreferrer'
            );
          } else {
            window.open(`${STRIPE_SETTINGS_URL}`, '_blank', 'noopener,noreferrer');
          }
        },
      },
      {
        title: 'Upgrade Your Membership',
        description: 'Check out the other membership options.',
        icon: {name: 'fi-rr-diamond', color: theme.colors.lighterBlue},
        onClick: () => {
          navigate('/memberships');
        },
      },
    ],
    [theme, STRIPE_SETTINGS_URL, user, navigate, handleSendSMS]
  );

  return (
    <Box px={4}>
      <Flex flexDir="column" gap="12px">
        {membershipButtonData.map((benefit, index) => (
          <BenefitCard
            key={index}
            icon={
              <Icon
                iconName={benefit.icon.name}
                style={{fontSize: '18px', color: benefit.icon.color}}
              />
            }
            title={benefit.title}
            description={benefit.description}
            onClick={benefit.onClick}
            // comingSoon={benefit.comingSoon}
          />
        ))}
      </Flex>
      <Box
        my="30px"
        onClick={() => {
          navigate('/standalone-search/event?passTypes=1');
        }}>
        <Flex alignItems="baseline" justifyContent="space-between">
          <TextG14 color={theme.colors.darkGrey} fontWeight="700">
            Come Out to an Upcoming Event
          </TextG14>
          <TextG12 color={theme.colors.blue} fontWeight="700">
            See all
          </TextG12>
        </Flex>
        <Box pos="relative" mt="10px" borderRadius="12px">
          <AbsoluteCenter>
            <Flex
              w="202px"
              background="#7D3EEE"
              borderRadius="15px"
              px={3}
              py={2}
              alignItems="center"
              gap="6px">
              <TextG10 color="white" fontWeight="500">
                Join Everyone at the Next Event
              </TextG10>
              <Icon
                iconName="fi-rr-arrow-right"
                style={{color: 'white', fontSize: '10px', width: '10px', height: '10px'}}
              />
            </Flex>
          </AbsoluteCenter>
          <Box
            pos="absolute"
            top="0px"
            right="0px"
            w="100%"
            h="100%"
            background="#0000001F"
            borderRadius="12px"
          />
          <Image borderRadius="12px" src={EventImage} />
        </Box>
      </Box>
    </Box>
  );
  // const benefits = hasThisMembership || success ? membership.post_purchase_description : membership.benefits;
  // return (
  //   <div
  //     style={{margin}}
  //     dangerouslySetInnerHTML={{__html: benefits}}
  //     color={theme.colors.grey}></div>
  // );
};

export default MembershipAbout;
