import React, {useEffect, useState} from 'react';
import {Flex, Image, useTheme, Box, Center, Spinner} from '@chakra-ui/react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import Header from './Header';
import {Event} from '../../types/acrticle';
import BackButton from '../../components/BackButton';
import {SpinnerLoader} from '../../components/Loader';
import DefaultEventImage from '../../assets/images/default_event.png';
import EventAbout from './About';
import FallbackImg from '../../assets/images/fallback.jpg';
import {useFetchEvent} from '../../hooks/useEvent';
import {useOffers} from '../../hooks/useOffers';
import LocationDisabled from '../../components/LocationDisabled';
import EventTooltips from '../../components/TutorialTooltips/EventTooltips';
import TutorialTooltips from '../../components/TutorialTooltips';
import {useFetchUserTickets} from '../../hooks/useUserTickets';
import {filter} from 'lodash';
import {TextG14, TextG16} from '../../components/typography';
import useAuth from '../../hooks/useAuth';

const Event = ({onClose}: {onClose?: () => void}) => {
  const {eventId} = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const fetchEvent = useFetchEvent(Number(eventId || searchParams.get('id')));
  const [locationDisabled, setLocationDisabled] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);
  const isTutorial = !!searchParams.get('tutorial');
  const tutorialViewed = localStorage.getItem('events_tutorial_viewed');
  const {offers, offersIsLoading} = useOffers({
    articleType: 'eventId',
    articleId: eventId || searchParams.get('id'),
  });

  const {user} = useAuth();

  const fetchTickets = useFetchUserTickets(!!user);

  const event: Event = fetchEvent.data?.data;
  const tickets = fetchTickets.data?.data;

  const eventTickets = filter(
    tickets,
    (ticket) => ticket.event_id == (eventId || searchParams.get('id'))
  );

  const userHasTickets = eventTickets && eventTickets.length > 0;

  const isLoading =
    fetchEvent.isLoading || fetchTickets.isLoading || offersIsLoading || event?.is_meetup;

  useEffect(() => {
    if (event) {
      if (event.is_meetup) {
        navigate(`/meet-up/${event.id}`, {replace: true});
        return;
      }
      if (!event.allow_check_in) {
        return;
      }
      // Tooltip highlights won't work correctly inside of a modal page,
      // so if this is a modal page navigate to the event's full page
      if (event && !!searchParams.has('id') && !!searchParams.get('tutorial')) {
        navigate(`/event/${event.id}?tutorial=t`, {replace: true});
        return;
      }

      setShowTutorial(isTutorial);

      if (isTutorial) window.scrollTo(0, 0);
      localStorage.setItem('events_tutorial_viewed', 'true');
    }
  }, [isTutorial, event]);

  useEffect(() => {
    if (!tutorialViewed) {
      const url = new URL(location.href);
      if (!url.searchParams.get('tutorial')) url.searchParams.append('tutorial', 't');
      const path = url.pathname + url.search;
      navigate(path, {replace: true});
    }
  }, []);

  const navigateBack = React.useCallback(() => {
    if (searchParams.has('id') && onClose) {
      onClose();
      return;
    }
    navigate(-1);
  }, []);

  if (isLoading || event === undefined)
    return (
      <Center style={centerCss}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );

  if (locationDisabled) return <LocationDisabled />;

  return (
    <>
      {showTutorial && <TutorialTooltips tooltipFunc={EventTooltips} />}
      <Flex position="relative" height="250px">
        <BackButton onClick={navigateBack} m={'.75rem'} pos="relative" />
        <Image
          fallbackStrategy="onError"
          fallbackSrc={FallbackImg}
          src={event?.image_url || DefaultEventImage}
          alt="cover image"
          position="absolute"
          width="100%"
          height="100%"
          objectFit="cover"
          zIndex="-1"
          borderBottomRadius="14px"
        />
      </Flex>
      <Header
        event={event}
        eventOffers={offers}
        disableLocation={() => setLocationDisabled(true)}
      />
      <EventAbout event={event} eventOffers={offers} />
    </>
  );
};

export default Event;

const centerCss: React.CSSProperties = {
  minHeight: '100vh',
};
